import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import brandidentity1 from "../assets/images/Brandidentity/brandidentity1.png";
import brandidentity2 from "../assets/images/Brandidentity/brandidentity2.png";
import brandidentity3 from "../assets/images/Brandidentity/brandidentity3.png";
import Brand1 from "../assets/images/Brandidentity/Brand1.png";
import brand2 from "../assets/images/Brandidentity/Brand2.png";
import Brand3 from "../assets/images/Brandidentity/Brand3.png";
import Brand4 from "../assets/images/Brandidentity/Brand4.png";
import Brand5 from "../assets/images/Brandidentity/Brand5.png";
import Brand6 from "../assets/images/Brandidentity/Brand6.png";
import icon1 from "../assets/images/Brandidentity/icon1.png";
import icon2 from "../assets/images/Brandidentity/icon2.png";
import icon3 from "../assets/images/Brandidentity/icon3.png";
import icon4 from "../assets/images/Brandidentity/icon4.png";
import icon5 from "../assets/images/Brandidentity/icon5.png";
import icon6 from "../assets/images/Brandidentity/icon6.png";
import { Helmet } from "react-helmet";

const BrandIdentity = () => {
  return (
    <div>
      <Helmet>
        <title>
          Build Your Financial Brand Identity with Athena | Fathena.com
        </title>
        <meta
          name="description"
          content="Elevate your financial enterprise with Athena's expert brand identity services. Tailored branding solutions to distinguish your business in the competitive markets of Forex, stocks, gold, and cryptos."
        />
        <meta
          name="keywords"
          content="branding services, Forex brand development"
        />
      </Helmet>
      <div className="Brand_Identity_bg">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-xs-6 p-0">
              <div className="sm-bg">
                <h2 className="Brand_Heading">BRAND</h2>
                <h2 className="Identity_Heading">Identity</h2>
              </div>
              <div className="p-12">
                <div className="BrandIdentity_P">
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Stand out from the crowd
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Communicate company’s personality
                  </p>
                  <p>
                  <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Comprehensive brand strategy planning
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around ">
          <div className="   col-xl-6 col-md-7 col-12  BRandIdentityBrandStrategy">
            <h2>Brand Strategy &amp; Visual Identity</h2>
            <h3>
              <b>Define Your Distinct</b>: Masterfully Crafting Unique Brand
              Strategies to Elevate Your Presence and Command Your Crowd
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 BrandIdentitys3_image ">
            <img
              className="BrandImagedisplayno"
              src={brandidentity1}
              alt="images"
            />
            <div className="BrandImagedisplaysmallscreen">
              <img src={Brand1} alt="images" />
              <img src={brand2} alt="images" />
              <img src={Brand3} alt="images" />
              <img src={Brand4} alt="images" />
              <img src={Brand5} alt="images" />
              <img src={Brand6} alt="images" />
            </div>
          </div>
        </div>
      </div>
      <div className="BrandIdentitySectionBgimg">
        <div className="container">
          <div className="row BRandIdentityStrategy_ROw ">
            <div className=" col-12 col-md-8 col-lg-6   BRandIdentityStrategy">
              <h2>Brand Strategy </h2>
              <h3>
                With the massive amount of brands in today’s markets, it’s
                essential to distinguish your brand from others through
                developing a brand strategy.
              </h3>
              <div className=" StrategyBrandConvey">
                <img src={icon1} />
                <p>
                  We get to know our clients to understand the core values
                  they’d like their brand to convey.
                </p>
              </div>
            </div>
            <div className=" col-12 col-md-12 col-lg-4 d-block d-lg-none mt-4 ">
              <div className="BrandIdentityStrategyInnerS2C4 text-center text-lg-start">
                <img src={icon2} />
                <p>
                  Then, we analyze the market to determine the best way for your
                  brand to reach targeted audiences, while going above and
                  beyond what their competitors are doing.
                </p>
              </div>
            </div>
            <div className=" col-12 col-md-4 col-lg-6  strategyimage">
              <img src={brandidentity2} />
            </div>
          </div>
          <div className="row BrandIdentityStrategyInnerS2">
            <div className=" col-12 col-md-12 col-lg-4 d-none d-lg-block ">
              <div className="BrandIdentityStrategyInnerS2C4 text-center text-lg-start">
                <img src={icon2} />
                <p>
                  Then, we analyze the market to determine the best way for your
                  brand to reach targeted audiences, while going above and
                  beyond what their competitors are doing.
                </p>
              </div>
            </div>
            <div className=" col-12 col-md-12 col-lg-8 d-flex align-items-end">
              <div className="BrandIdentityStrategyInnerS2C8  mt-0">
                <img src={icon3} />
                <p>
                  By the end, we’ll have a comprehensive plan on how to
                  effectively communicate your company’s personality, function,
                  and message through a cohesive and authentic brand strategy.
                </p>
              </div>
            </div>
          </div>
          <div className="row BRandIdentityStrategy_ROw BRandIdentityStrategy_ROwS2">
            <div className="  col-12 col-md-8 col-lg-6  BRandIdentityStrategy ">
              <h2>Visual Identity </h2>
              <h3 style={{ color: "white" }}>
                Oftentimes, your brand’s visual identity will be the first thing
                that interacts with consumers, and creates a lasting impression.
              </h3>
              <div className=" StrategyBrandConvey strategys3c2">
                <img src={icon4} />
                <p>
                  Therefore, it is essential to have a visual design that
                  appeals to your audience while conveying trust and emotion.
                </p>
              </div>
            </div>
            <div className=" col-12 col-md-12 col-lg-4 d-block d-lg-none mt-4">
              <div className="BrandIdentityStrategyInnerS2C4 text-center text-lg-start">
                <img src={icon5} />
                <p>
                  At Athena, we first work with you to help you identify what
                  message you want your brand to communicate visually.
                </p>
              </div>
            </div>
            <div className=" col-12 col-md-4 col-lg-6  strategyimagec8">
              <img src={brandidentity3} />
            </div>
          </div>
          <div className="row BrandIdentityStrategyInnerS2">
            <div className=" col-12 col-md-12 col-lg-4 d-none d-lg-block ">
              <div className="BrandIdentityStrategyInnerS2C4 text-center text-lg-start">
                <img src={icon5} />
                <p>
                  At Athena, we first work with you to help you identify what
                  message you want your brand to communicate visually.
                </p>
              </div>
            </div>
            <div className=" col-12 col-md-12 col-lg-8 d-flex align-items-end">
              <div className="BrandIdentityStrategyInnerS2C8  mt-0">
                <img src={icon6} />
                <p>
                  Then, our specialists utilize advanced design strategies and
                  color psychology to create a unique logo that will leave a
                  lasting impression on anyone who sees it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around ">
          <div className=" col-12 col-md-8 col-lg-6  BRandIdentitys4">
            <h2> Our Process </h2>
            <h3>
              Create the right image for your clients not just in any industry
              but in forex industry.
            </h3>
          </div>
        </div>
        <div className="row BrandIdentitySection4bg">
          <div className="col-12 ">
            <div className="brandidentitys4inner1">
              <h2> 1. Identify </h2>
              <p>
                First, we work with you to determine your brand’s strengths that
                set it apart from others within its industry.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 brandidentitys4inner2">
            <div className="brandidentitys4inner2c ">
              <h2> 2. Plan </h2>
              <p>
                Next, we help you choose the message, values, and unique
                personality that will make up your brand identity.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="brandidentitys4inner2cr">
              <h2> 3. Design </h2>
              <p>
                Everything you would like to show off your brand can be designed
                by us – from logos and business cards to merchandise.
              </p>
            </div>
          </div>
          <div className="col-12 ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2> 4. Utilize </h2>
              <p>
                After everything is done, Athena will guide you on how to
                successfully utilize your new brand identity and visual design
                in a cohesive way.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BrandIdentity;
