import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import fathenalogo from "../assets/images/fathena.png";
import menu from "../assets/images/menu.png";
const Header = ({ onMenuClick }) => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      // Adjust this value as needed
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header className={`header ${isSticky ? "sticky" : ""}`}>
        <nav className="navbar">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="logo">
                <a href="/">
                  <img src={fathenalogo} alt="logo" style={{}} />
                </a>
              </div>
              <div className="d-flex align-items-center gap-7 ">
                <div className="d-none d-lg-block">
                  <ul
                    className="p-0 m-0 d-flex align-items-center gap-7 "
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <Link to="/hosting-service" className="nav-link fs-5">
                        Hosting
                      </Link>
                    </li>
                    <li>
                      <Link to="/trading-platform" className="nav-link fs-5">
                        Trading
                      </Link>
                    </li>
                    <li>
                      <Link to="/payment-solution" className="nav-link fs-5">
                        Payment
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/aggregated-liquidity"
                        className="nav-link fs-5"
                      >
                        Liquidity
                      </Link>
                    </li>
                  </ul>
                </div>
                <button
                  onClick={onMenuClick}
                  className="menu css-1ujsas3"
                  tabIndex="0"
                  type="button"
                >
                  <img src={menu} alt="menu" />
                  <span className="MuiTouchRipple-root css-w0pj6f"></span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
