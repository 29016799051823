import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import imge1 from "../assets/images/webdesign/imge1.png";
import imge2 from "../assets/images/webdesign/imge2.png";
import imge4 from "../assets/images/webdesign/imge4.png";
import imge5 from "../assets/images/webdesign/imge5.png";
import imge6 from "../assets/images/webdesign/imge6.png";
import imge7 from "../assets/images/webdesign/imge7.png";
import { Helmet } from "react-helmet";
const WebDesign = () => {
  return (
    <div>
      <Helmet>
        <title>
          Professional Web Design for Financial Services | Fathena.com
        </title>
        <meta
          name="description"
          content="Create a standout online presence with Athena's professional web design services tailored for the financial sector. Engage and convert your audience with our sleek, user-friendly, and secure website designs."
        />
        <meta
          name="keywords"
          content="financial services web design, website design for traders"
        />
      </Helmet>
      <div className="Brand_Identity_bg">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-xs-6 p-0">
              <div className="sm-bg">
                <h2 className="Brand_Heading">WEB</h2>
                <h2 className="Identity_Heading">Design</h2>
              </div>
              <div className="p-12">
                <div className="BrandIdentity_P">
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Build your digital presence
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Engage potential customers
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Convey trust and legitimacy
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Create mobile responsive designs
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around ">
          <div className="   col-xl-9 col-md-10 col-12  BRandIdentityBrandStrategy">
            <h2>Memorable, User-Friendly &amp; Future-Proof</h2>
            <h3>This is how we create a digital presence for your company.</h3>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12 col-md-6 webdesigns2_image">
            <img src={imge1} alt="images" />
          </div>
          <div className="col-12 col-md-6 Webdesignmemorable">
            <h2>
              Athena doesn’t just create web designs. We create an online hub of
              information for your brand that represents your business image and
              engages potential customers.
            </h2>
            <p>
              Our team works with you to ensure your business has an online
              presence that goes above and beyond the ordinary. Every step of
              the way, we collaborate with you and your team to create a digital
              platform that is visually appealing, easy to use, informative, and
              tells your company story.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around  ">
          <div className="   col-xl-6 col-md-8 col-12  BRandIdentityBrandStrategy  BRandIdentityBrandStrategy2 ">
            <h2>UX/UI Design</h2>
            <h3>
              In order to engage with users, your company’s website must have an
              immersive visual experience (UX) and a strong user interface (UI).
            </h3>
          </div>
          <div className="row">
            <div className="col-12 webdesigns2_image">
              <div className="d-block d-md-none">
                <p className="py-4 section-text2 text-black">
                  UX design collects and analyzes a massive amount of data to
                  target the customer’s wants and enhance their experience.
                </p>
                <p className="section-text2 text-black">
                  On the other side, UI design involves the website’s visual
                  presence – the look, feel, and operation experience.
                </p>
              </div>
              <img src={imge2} alt="images" />
              <div className="d-block d-md-none">
                <p className="section-text2 text-black">
                  Together, UX and UI create a product that is tailored to your
                  customer while communicating your brand’s image, purpose,
                  strengths, in an aesthetically pleasing way. This online,
                  integrated experience we create will connect your company to
                  the people who matter the most.{" "}
                </p>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BrandIdentitySectionBgimg WebDesignerSection">
        <div className="container Webdesignsectioncontainer1st">
          <div className="row d-flex justify-content-center flex-column align-content-around ">
            <div className="   col-xl-6 col-md-9 col-lg-7 col-12  webDesignSection3text ">
              <h2>Creative Direction</h2>
              <h3>
                At Athena, we don’t want your design to just be good. We want to
                create a design that is memorable, defines your brand, and
                impresses the customer.
              </h3>
            </div>
          </div>
          <div className="row adjust-tab ">
            <div className=" col-12 col-md-6  webdesignSection3img">
              <img src={imge4} />
            </div>
            <div className=" col-12 col-md-6 webdesignSection3c2 ">
              <h3>
                To do this, we combine aesthetics, functionality, and
                outstanding technology. By using color schemes, mood boards, and
                sample designs, we collaborate with you to develop a design that
                you confidently feel encompasses your ideas and creative vision
                and reflects your brand.
              </h3>
            </div>
            <div className=" col-12 col-md-5 col-lg-4 webdesignSection3c2 d-flex align-items-center">
              <h3>
                Our team of creative directors will transform your brand’s
                design into something that conveys trust and legitimacy to all
                audiences. After years of experience and innovation, you can
                rest assured knowing our design process is tailored to you and
                will exceed expectations.
              </h3>
            </div>
            <div className=" col-12 col-md-7 col-lg-8  webdesignSection3img">
              <img src={imge5} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around ">
          <div className="   col-xl-7 col-md-9 col-12  BRandIdentityBrandStrategy  WebdesignS4Mobile">
            <h2>Mobile Responsive Design</h2>
            <h3>
              A mobile responsive design of your brand allows customers the
              ability to access your business – anytime, anywhere.
            </h3>
          </div>
          <div className="row">
            <div className="col-12 BrandIdentitys3_image webdesignsection4image">
              <div className="d-block d-md-none">
                <br />
                <p className="section-text2 text-black">
                  It effectively resizes your site to function perfectly on any
                  phone, tablet, or desktop, while still including all content
                  and visual designs. In addition, your digital presence is made
                  stronger, as search engine algorithms prioritize sites with a
                  “mobile-first” approach. This means your site will attract
                  more customers, no matter where they are or what device they
                  are using.
                </p>
              </div>
              <img src={imge6} alt="" />
              <div className="d-block d-md-none">
                <p className="section-text2 text-black">
                  Our team of experienced coders will optimize your site across
                  all platforms, ensuring your website looks and works superbly
                  across all devices. Everything undergoes an extensive
                  user-testing and QA process, so it can run seamlessly. In the
                  end, you’ll have a cutting-edge website that outshines many
                  businesses today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around ">
          <div className=" col-12 col-md-8 col-lg-6  BRandIdentitys4 WebDesgins4">
            <h2> Our Process </h2>
            <h3>
              Create the right image for your clients not just in any industry
              but in forex industry.
            </h3>
          </div>
        </div>
        <div className="row WebDesignSection5bg">
          <div className="col-12 ">
            <div className="brandidentitys4inner1">
              <h2> 1. Plan </h2>
              <p>
                To develop a suitable design plan, we start by getting to know
                you and your company in order to pinpoint the goals, needs, and
                objectives for your website and brand.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 brandidentitys4inner2">
            <div className="brandidentitys4inner2c  ">
              <h2> 2. Explore </h2>
              <p>
                Your personalized design strategy is refined as we analyze your
                business’s competitors, audience, and industry.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="brandidentitys4inner2cr">
              <h2> 3. UX/UI </h2>
              <p>
                In this step, the overall structure, as well as specialized
                features and functions, are chosen for your website.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2> 4. Composition Planning </h2>
              <p>
                In order to determine what should be included on your website to
                inform customers and maximize business and where it should be
                placed, we communicate with your team every step of the way.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6  ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2>5. Design </h2>
              <p>
                Finally, all visual aspects are created to make your website
                vibrant and appealing to users.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebDesign;
