import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dm1 from "../assets/images/DM/digitalmarketing1.png";
import Dm2 from "../assets/images/DM/digitalmarketing2.png";
import Dm3 from "../assets/images/DM/digitalmarketing3.png";
import Dm4 from "../assets/images/DM/digitalmarketing4.png";
import Dm5 from "../assets/images/DM/digitalmarketing5.png";
import { Helmet } from "react-helmet";
const DigitalMarketing = () => {
  return (
    <div>
      <Helmet>
        <title>
          Digital Marketing Solutions for Financial Services | Fathena.com
        </title>
        <meta
          name="description"
          content="Amplify your financial brand with Athena's strategic digital marketing services. Specializing in SEO, PPC, content marketing, and social media strategies to drive growth and enhance online visibility for financial businesses."
        />
        <meta
          name="keywords"
          content="financial services marketing, SEO for finance,"
        />
      </Helmet>
      <div className="Brand_Identity_bg">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-xs-6 p-0">
              <div className="sm-bg">
                <h2 className="Brand_Heading">DIGITAL</h2>
                <h2 className="Identity_Heading">Marketing</h2>
              </div>
              <div className="p-12">
                <div className="BrandIdentity_P">
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Convert new audiences into loyal customers
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Push your message to the right audiences
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Digital marketing strategy that fits your business
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around ">
          <div className="   col-xl-6 col-md-9 col-lg-7 col-12  BRandIdentityBrandStrategy">
            <h2>Content Marketing</h2>
            <h3>
              Content marketing is the distribution of relevant information
              about your company to the appropriate audience.
            </h3>
          </div>
        </div>
        <div className="row Digitalmarketingsection2image ">
          <div className="col-12 BrandIdentitys3_image">
            <img src={Dm1} alt="images" />
            <div className="d-block d-md-none d-lg-none">
              <p className="section-text2 text-black">
                Our specialized maintenance team ensures everything is running
                smoothly, so you can focus on what is important to you. For any
                technical issue that may arise, we are here to help in a timely
                and effective manner.
              </p>
              <p className="py-4 section-text2 text-black">
                Athena offers various different website monitoring plans that
                will monitor your site 24/7. Other services, such as virus and
                malware scans, hacker prevention systems, scheduled backups, and
                overall site management are provided.
              </p>
              <p className="section-text2 text-black">
                To ensure all consumers are able to experience your site
                seamlessly and as it should be, our web hosting service makes
                site speed and performance its number one priority. In other
                words, you can rest stress-free knowing your site will be
                functioning at its best.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="BrandIdentitySectionBgimg  webdevelopmentbgection4">
        <div className="container Webdesignsectioncontainer1st  DigitalMarketingContainer">
          <div className="row d-flex justify-content-center flex-column align-content-around ">
            <div className="   col-xl-6 col-md-9 col-lg-7 col-12   DigitalmarketingSection3text ">
              <h2>Email Marketing</h2>
              <h3>
                Email marketing is one of the most consistent and personal ways
                to connect with existing customers and keep them engaged with
                your brand.
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-5"></div>
            <div className=" col-12 col-md-7 giditalmarketingsection3col d-flex align-items-center">
              <h3>
                Athena uses a powerful email algorithm that combines relevant
                information, proper timing and frequency, and an attractive
                design.
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div
              className=" col-12  col-md-10  digitalmarketingSection3img "
              style={{ zindex: "1;" }}
            >
              <img src={Dm2} />
            </div>
          </div>
          <div className="row  digitalmarketingsection3s2c1  digitalamrketingSection3dublicate">
            <div className=" col-12 col-xl-4 col-md-5 digitalmarketingsection3sc1 d-flex align-items-center">
              <h3>
                Over time, we track which emails have the highest engagement
                rate and analyze user information to best fit our email
                marketing strategy to your customer base.
              </h3>
            </div>
            <div className="col-xl-4 col-md-3 col-12"></div>
            <div className=" col-12 col-md-4 digitalmarketingsection3sc1 d-flex align-items-center">
              <h3>
                This way, your customers are kept happy while optimizing your
                results.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around">
          <div className="col-xl-6 col-md-7 col-12 BRandIdentityBrandStrategy DigitalMarketingS4Search">
            <h2>Search Engine Marketing</h2>
            <h3>
              With the immeasurable amount of information out there online, it
              is crucial that potential customers are able to quickly find your
              business in the continuously growing competitive market.
            </h3>
          </div>
          <div className="col-12 col-md-6 col-lg-5 digitalmarketingsection4textimg">
            <p style={{ paddingLeft: "80px" }}>
              By using search engine marketing, consumers who are searching for
              your product or service are shown specially created ads in the
              right place at the right time.
              <br />
              <br />
              To set your business apart from the crowd, Athena analyzes the
              trends of competitors and your industry before and during
              marketing campaigns to ensure your business is always in favor.
              New and returning customers will be driven to your website, no
              matter the platform.
            </p>
          </div>
        </div>
        <div className="row Digitalmarketingsection2image DigitalmarketingSectionimagetop">
          <div className="col-12 Digitalmarketingsection4images">
            <img src={Dm3} alt="Search Engine Marketing" />
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row d-flex justify-content-around  ">
          <div className="   col-xl-6 col-md-7 col-12  BRandIdentityBrandStrategy DigitalMArketingS5Search">
            <h2>Search Engine Optimization</h2>
            <h3>
              Search engine optimization is the best way for your business to
              appear at the top of the search results page.
            </h3>
          </div>
        </div>
        <div className="row Digitalmarketingsection2image ">
          <div className="col-12 BrandIdentitys3_image Digitalmarketingsection4images ">
            <img src={Dm4} alt="images" />
            <div className="d-block d-md-none mt-4">
              <p className="section-text2 text-black">
                Therefore, we can match the most relevant keywords and content
                to your brand to ensure your site is optimized among search
                results.
              </p>
              <p className="py-4 section-text2 text-black">
                Athena uses a constantly-running system to track how users
                utilize and interact with search engines online.
              </p>
              <p className="section-text2 text-black">
                This means your company is easily visible to the public, and
                potential customers are directly sent to your site rather than
                your competitors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row d-flex justify-content-around  ">
          <div className="   col-xl-6 col-md-7 col-12  BRandIdentityBrandStrategy DigitalMArketingS5Search">
            <h2>Social Media Marketing</h2>
            <h3>
              With the ever-growing amount of social media users, more and more
              businesses are using social media marketing to connect to
              consumers.
            </h3>
          </div>
        </div>
        <div className="row Digitalmarketingsection2image  ">
          <div className="col-12 BrandIdentitys3_image Digitalmarketingsection4images">
            <img src={Dm5} alt="images" />
            <div className="d-block d-md-none mt-4">
              <p className="section-text2 text-black">
                Social media marketing is a powerful tool to appeal to targeted
                audiences through the use of strategic campaigns.
              </p>
              <p className="py-4 section-text2 text-black">
                Some examples include micro/macro influencer involvement,
                large-scale advertisement implementation, community group
                cultivation, and social listening. The end result is an increase
                in brand awareness, a broader customer base, and higher rates of
                customer conversion.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container digitalmarketingsection7process">
        <div className="row d-flex justify-content-around ">
          <div className=" col-12 col-md-8 col-lg-6  BRandIdentitys4 digitalmarketingsection7ourprocess ">
            <h2> Our Process </h2>
            <h3>
              Create the right image for your clients not just in any industry
              but in forex industry.
            </h3>
          </div>
        </div>
        <div className="row digitalmarketingSection7bg">
          <div className="col-12 ">
            <div className="brandidentitys4inner1">
              <h2> 1. Discovery </h2>
              <p>
                Athena will analyze your business’s industry, consumer base, and
                competition to develop the best strategy for you.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 brandidentitys4inner2">
            <div className="brandidentitys4inner2c  ">
              <h2> 2. Discussion </h2>
              <p>
                At the start, and throughout every step of the process, we
                communicate with you to learn your specific objectives and
                timeline goals.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="brandidentitys4inner2cr">
              <h2> 3. Planning</h2>
              <p>
                After fully understanding your business and what sets it apart
                from others, multi-platform marketing strategies are created
                around your wants and your brand’s specialty.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2> 4. Implementation </h2>
              <p>
                Your chosen strategies are seamlessly implemented across various
                platforms.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6  ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2>5. Maintenance </h2>
              <p>
                Our Athena team monitors and analyzes your ongoing campaigns,
                making adjustments when necessary to ensure your campaign is
                successful.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DigitalMarketing;
