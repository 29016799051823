import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeHeroVideo from "../assets/images/Homevideo.mp4";
import hero from "../assets/images/Home_Hero.jpg";
import about from "../assets/images/about.png";
import brand1 from "../assets/images/brand1.png";
import brand2 from "../assets/images/brand2.png";
import brand3 from "../assets/images/brand3.png";
import brand4 from "../assets/images/brand4.png";
import brand5 from "../assets/images/brand5.png";
import home2 from "../assets/images/home/home2.png";
import home3 from "../assets/images/home/home3.png";
import home4 from "../assets/images/home/home4.png";
import home5 from "../assets/images/home/home5.png";
import { Helmet } from "react-helmet";
const Home = () => {
  useEffect(() => {
    const videoElement = document.getElementById("canvabannervideo");
    if (videoElement) {
      videoElement
        .play()
        .then(() => {
          // Autoplay started successfully
        })
        .catch((error) => {
          // Autoplay was prevented
          console.error("Autoplay was prevented: ", error);
        });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Secure Payment Solutions for Traders | Fathena.com</title>
        <meta
          name="description"
          content="Enhance your trading experience with Athena's secure payment solutions. Offering fast, reliable, and secure transactions for Forex, stocks, gold, and crypto trading. Tailored to meet the needs of global financial markets."
        />
        <meta
          name="keywords"
          content="trading payment systems,  crypto payment solutions, "
        />
      </Helmet>
      <div className="container-fluid canvabanner-container p-0">
        <div
          className="blue-bannar home_header"
          style={{ backgroundImage: `url(${hero})` }}
        >
          <div className="container-fluid">
            <div className="row my-4 align-items-center flex-row-reverse">
              <div className="col-12">
                <div className="d-flex align-items-center h-100">
                  <div
                    className="canvabannerContainer text-right pt-5 pt-lg-0"
                    id="canvabanner_text"
                  >
                    <h5>
                      Complete IT Solutions for Trading <br />
                      and Brokerage Firms Starting at
                    </h5>
                    <br />
                    <h2>$2,500 Per Month</h2>
                    <br />
                    <p>
                      simplify your financial operations <br /> from budgeting
                      to branding.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center BrandSPaceHomePage my-5 ">
          <div className="col-12 col-md-6">
            <img
              className="w-100 h-auto"
              src={about}
              alt=""
              style={{ filter: "grayscale(100%)" }}
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="homebannarcontact left-align">
              <h1>Build Your Brand with Athena.</h1>
              <h3 className="my-3 my-lg-4 bluetitle">
                Simple, Fast, and Cost-Efficient Trading for Forex, Stocks,
                Gold, and Cryptos.
              </h3>
              <p>
                At Athena, we specialize in creating robust platforms for
                trading and brokerage companies. Utilizing our deep market
                knowledge and broad industry connections, we equip our clients
                with the tools they need to quickly and effectively establish a
                strong presence in the industry.
              </p>
              <div className="text-center text-lg-start">
                <a
                  className="fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                  tabIndex="0"
                  type="button"
                  href="/contact-us"
                >
                  Contact Us
                  <span className="MuiTouchRipple-root css-w0pj6f"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="hosting-services-content mb-5 text-center py-lg-5 py-2 ">
          <h2 className="titleline">Trading Capabilities</h2>
          <h3 className="section-title">Hosting service</h3>
          <img className="w-100 h-auto py-3 py-lg-5" src={home2} alt="" />
          <p className="section-text  text-center">
            Utilizing AWS and Equinix data centers, we provide low-latency
            connectivity directly to the financial ecosystem. This strategic
            placement near major liquidity providers for Forex, Stocks, Gold,
            and Cryptos allows us to reduce trade request times to less than a
            hundredth of a millisecond, enhancing execution speed and
            efficiency.
          </p>
          <a
            className="fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
            tabIndex="0"
            type="button"
            href="/hosting-service"
          >
            Learn More<span className="MuiTouchRipple-root css-w0pj6f"></span>
          </a>
        </div>
      </div>
      <div className="container">
        <div className="trading-services-content mb-5 text-center py-lg-5 py-4">
          <h2 className="section-title ">Trading Platform</h2>
          <img className="w-100 h-auto py-3 py-lg-5" src={home3} alt="" />
          <p className="section-text">
            Athena provides rapid and user-friendly trading platforms for a
            diverse range of financial markets, including Forex, Stocks, Gold,
            and Cryptos. Our offerings include a variety of trading and
            reporting APIs, customizable to meet the specific requirements of
            your online trading services.
          </p>
          <a
            className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
            tabIndex="0"
            type="button"
            href="/trading-platform"
          >
            Learn More<span className="MuiTouchRipple-root css-w0pj6f"></span>
          </a>
        </div>
      </div>
      <div className="container">
        <div className="payment-services-content mb-5 py-lg-5 py-4 text-center">
          <h2 className="section-title">Payment Solution</h2>
          <img className="w-100 h-auto py-3 py-lg-5" src={home4} alt="" />
          <p className="section-text">
            {" "}
            We facilitate worldwide payment acceptance by integrating
            traditional banking methods with blockchain solutions. Our approach
            combines local and international bank and credit card payments with
            blockchain technologies, allowing you to manage transactions
            effortlessly.{" "}
          </p>
          <a
            className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
            tabIndex="0"
            type="button"
            href="/payment-solution"
          >
            Learn More<span className="MuiTouchRipple-root css-w0pj6f"></span>
          </a>
        </div>
      </div>
      <div className="container">
        <div className="aggregated-services-content py-lg-5 py-2 mb-5 text-center">
          <h2 className="section-title">Aggregated Liquidity</h2>
          <img className="w-100 h-auto py-3 py-lg-5" src={home5} alt="" />
          <p className="section-text">
            Our liquidity solution leverages an aggregated market depth from
            various providers through a lightweight gateway, including
            Metaquotes. This setup ensures low-latency trade execution within
            the AWS and Equinix global ecosystems, optimizing performance and
            reliability for traders in Forex, Stocks, Gold, and Cryptos.{" "}
          </p>
          <a
            className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
            tabIndex="0"
            type="button"
            href="/aggregated-liquidity"
          >
            Learn More<span className="MuiTouchRipple-root css-w0pj6f"></span>
          </a>
        </div>
      </div>
      <div className="container">
        <div className="row"></div>
        <div className=" col-12 bran-identity-content py-lg-5 ">
          <h2 className="titleline text-lg-start my-lg-4 pb-0 pt-4">
            Presence Capabilities
          </h2>
        </div>
      </div>
      <div className="BrandIdentityHomebg ">
        <div className="container ">
          <div className="row BrandSPaceHomePage">
            <div className="col-12 col-md-6 mb-5 mb-lg-0">
              <h2 className="bold-title brandTitleHome">
                BRAND <br />
                <b>Identity</b>
              </h2>
              <img
                className="w-100 h-auto  d-md-none mb-5"
                src={brand1}
                alt=""
              />
              <div className="check-p">
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="mb-1"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Stand out from the crowd
                </p>
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="mb-1"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Brand strategy and visual identity
                </p>
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="mb-1"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Communicate company’s personality
                </p>
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="mb-1"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Comprehensive brand strategy planning
                </p>
                <div className="text-start">
                  <a
                    className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                    tabIndex="0"
                    type="button"
                    href="/Brand-Identity"
                  >
                    SEE MORE
                    <span className="MuiTouchRipple-root css-w0pj6f"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-5 mb-lg-0 d-none d-md-block">
              <img className="w-100 h-auto " src={brand1} alt="" />
            </div>
          </div>
          <div className="row   BrandSPaceHomePage">
            <div className="col-12 col-md-6 mb-5 mb-lg-0 d-none d-md-block">
              <img className="w-100 h-auto " src={brand2} alt="" />
            </div>
            <div className="col-12 col-md-6 mb-5 mb-lg-0 text-center text-lg-end ">
              <h2 className="bold-title brandTitleHome">
                WEB <br />
                <b>Design</b>
              </h2>
              <img
                className="w-100 h-auto  d-md-none mb-5"
                src={brand2}
                alt=""
              />
              <div className="d-flex justify-content-start justify-content-lg-center">
                <div className="check-p ">
                  <p className="align-items-center  text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Build your digital presence
                  </p>
                  <p className="align-items-center  text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Engage potential customers
                  </p>
                  <p className="align-items-center  text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Convey trust and legitimacy
                  </p>
                  <p className="align-items-center  text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Create mobile responsive designs
                  </p>
                  <div className="text-start">
                    <a
                      className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                      tabIndex="0"
                      type="button"
                      href="/web-design"
                    >
                      SEE MORE
                      <span className="MuiTouchRipple-root css-w0pj6f"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BrandIdentityHomebg2 ">
        <div className="container">
          <div className="row BrandSPaceHomePage ">
            <div className="col-12 col-md-6 mb-5 mb-lg-0 ">
              <h2 className="bold-title brandTitleHome">
                WEB <br />
                <b>Development</b>
              </h2>
              <img
                className="w-100 h-auto  d-md-none mb-5"
                src={brand3}
                alt=""
              />
              <div className="check-p">
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2 ">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Seamless user experience
                </p>
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2 ">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Comprehensive competitors’ analysis
                </p>
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2 ">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Experienced teams of quality assurance
                </p>
                <p className="align-items-center text-black d-flex gap-1 gap-lg-2 ">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(85, 132, 255)" }}
                  >
                    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                  </svg>
                  Highly immersive app development
                </p>
                <div className="text-start">
                  <a
                    className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                    tabIndex="0"
                    type="button"
                    href="/web-development"
                  >
                    SEE MORE
                    <span className="MuiTouchRipple-root css-w0pj6f"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-5 mb-lg-0 d-none d-md-block">
              <img className="w-100 h-auto " src={brand3} alt="" />
            </div>
          </div>
          <div className="row BrandSPaceHomePage ">
            <div className="col-12 col-md-6  d-none d-md-block">
              <img className="w-100 h-auto " src={brand4} alt="" />
            </div>
            <div className="col-12 col-md-6 mb-5 mb-lg-0 text-center text-lg-end ">
              <h2 className="bold-title brandTitleHome">
                DIGITAL
                <br />
                <b>Marketing</b>
              </h2>
              <img
                className="w-100 h-auto  d-md-none mb-5"
                src={brand4}
                alt=""
              />
              <div className="d-flex justify-content-start justify-content-lg-center">
                <div className="check-p ">
                  <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Convert new audiences into loyal customers
                  </p>
                  <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Push your brand message to the right audiences
                  </p>
                  <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    A powerful email algorithm
                  </p>
                  <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Search Engine Marketing
                  </p>
                  <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Social Media Marketing
                  </p>
                  <div className="text-start">
                    <a
                      className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                      tabIndex="0"
                      type="button"
                      href="/digital-marketing"
                    >
                      SEE MORE
                      <span className="MuiTouchRipple-root css-w0pj6f"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row BrandSPaceHomePage">
          <div className="col-12 col-md-6 ">
            <h2 className="bold-title brandTitleHome">
              WEB <br />
              <b>Maintenance</b>
            </h2>
            <img className="w-100 h-auto  d-md-none mb-5" src={brand5} alt="" />
            <div className="check-p">
              <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(85, 132, 255)" }}
                >
                  <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                </svg>
                Speed and performance are priorities
              </p>
              <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(85, 132, 255)" }}
                >
                  <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                </svg>
                24 / 7 website monitoring plans
              </p>
              <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(85, 132, 255)" }}
                >
                  <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                </svg>
                Hacker prevention systems
              </p>
              <p className="align-items-center text-black d-flex gap-1 gap-lg-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(85, 132, 255)" }}
                >
                  <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                </svg>
                Scheduled backups
              </p>
              <div className="text-start">
                <a
                  className=" fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                  tabIndex="0"
                  type="button"
                  href="/web-maintenance"
                >
                  SEE MORE
                  <span className="MuiTouchRipple-root css-w0pj6f"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 mb-lg-0 d-none d-md-block">
            <img className="w-100 h-auto " src={brand5} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
