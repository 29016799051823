import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import maintainence1 from '../assets/images/WebM/maintainence1.png';
import maintainence2 from '../assets/images/WebM/maintainence2.png';
import maintainence3 from '../assets/images/WebM/maintainence3.png';
import { Helmet } from 'react-helmet';
const WebMaintenance = () => {
return(
<div>
<Helmet>
        <title>Comprehensive Web Maintenance for Financial Websites | Fathena.com</title>
        <meta name="description" content="Ensure your financial website operates flawlessly with Athena's comprehensive web maintenance services. Our team provides ongoing support, security updates, and performance enhancements to keep your site secure and efficient." />
        <meta name="keywords" content="financial website maintenance, financial web services" />
      </Helmet>
   <div className="Brand_Identity_bg">
      <div className="container">
         <div className="row ">
            <div className="col-12 col-xs-6 p-0">
               <div className="sm-bg">
                  <h2 className="Brand_Heading">WEB HOSTING</h2>
                  <h2 className="Identity_Heading">Maintenance</h2>
               </div>
               <div className="p-12">
                  <div className="BrandIdentity_P">
                     <p>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Convert new audiences into loyal customers
                     </p>
                     <p>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Push your message to the right audiences
                     </p>
                     <p>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Digital marketing strategy that fits your business
                     </p>
                  </div>
               </div>
            </div>
            <div className="col-6"></div>
         </div>
      </div>
   </div>
   <div className="container">
      <div className="row d-flex justify-content-around ">
         <div className="   col-xl-6 col-md-7 col-12  BRandIdentityBrandStrategy">
            <h2>Our Service Plans</h2>
            <h3>Athena is specialized in your industry.</h3>
         </div>
      </div>
      <div className="row">
         <div className="col-12 BrandIdentitys3_image">
            <img src={maintainence1} alt="images"/>
            <div className="d-block d-md-none mb-5 pb-sm-5 pb-3">
               <p className="section-text2 text-black">Our specialized maintenance team ensures everything is running smoothly, so you can focus on what is important to you. For any technical issue that may arise, we are here to help in a timely and effective manner.</p>
               <p className="py-4 section-text2 text-black">Athena offers various different website monitoring plans that will monitor your site 24/7. Other services, such as virus and malware scans, hacker prevention systems, scheduled backups, and overall site management are provided.</p>
               <p className="section-text2 text-black">To ensure all consumers are able to experience your site seamlessly and as it should be, our web hosting service makes site speed and performance its number one priority. In other words, you can rest stress-free knowing your site will be functioning at its best.</p>
            </div>
         </div>
      </div>
   </div>
   <div className="BrandIdentitySectionBgimg  webmaintainencebgection4">
      <div className="container Webmaintainencecontainer1st">
         <div className="row d-flex justify-content-center flex-column align-content-around ">
            <div className="   col-xl-7 col-md-8 col-12   DigitalmarketingSection3text ">
               <h2>Web Hosting Service</h2>
               <h3> Athena, we utilize secure cloud service platforms to allow your website to run securely and smoothly.</h3>
            </div>
         </div>
         <div className="d-none d-lg-block">
            <div className="row  digitalmarketingsection3s2c1 ">
               <div className=" col-12  col-xl-4 col-md-5 digitalmarketingsection3sc1 d-flex align-items-center">
                  <h3>Multiple web servers across different data centers.</h3>
               </div>
               <div className="col-xl-4 col-md-3 col-12"></div>
               <div className=" col-12 col-md-4 ">
                  <div className="digitalmarketingsection3sc1 webmaintaincesectioncol d-flex align-items-center w-100">
                     <h3>CPU utilization support for high traffics .</h3>
                  </div>
               </div>
            </div>
         </div>
         <div className="row d-flex justify-content-center">
            <div className=" col-12  col-md-12  webmaintenanceSection3img " style={{zindex: "1"}}><img src={maintainence2}/></div>
         </div>
         <div className="d-block d-lg-none">
            <div className="row  digitalmarketingsection3s2c1 ">
               <div className=" col-12  col-xl-4 col-md-5 digitalmarketingsection3sc1 d-flex align-items-center">
                  <h3>Multiple web servers across different data centers.</h3>
               </div>
               <div className="col-xl-4 col-md-3 col-12"></div>
               <div className=" col-12 col-md-4 my-4 ">
                  <div className="digitalmarketingsection3sc1 webmaintaincesectioncol d-flex align-items-center w-100">
                     <h3>CPU utilization support for high traffics .</h3>
                  </div>
               </div>
            </div>
         </div>
         <div className="row">
            <div className=" col-12 col-md-7 giditalmarketingsection3col d-flex align-items-center w-100">
               <h3>Load balancing and autoscaling resources.</h3>
            </div>
         </div>
      </div>
   </div>
   <div className="container" >
      <div className="row d-flex justify-content-around ">
         <div className="   col-xl-8 col-md-10 col-12  BRandIdentityBrandStrategy">
            <h2 style={{paddingTop: "100px" }}>Web Management</h2>
            <h3>Ensuring your website stays ahead of the game.</h3>
         </div>
      </div>
      <div className="row">
         <div className="col-12 BrandIdentitys3_image">
            <img src={maintainence3} alt="images"/>
            <div className="d-block d-md-none d-lg-none">
               <p className="section-text2 text-black pb-3">Security and features audits.</p>
               <p className="section-text2 text-black pb-3">Module and theme updates.</p>
               <p className="section-text2 text-black pb-3">Integrate new functionalities.</p>
               <p className="section-text2 text-black pb-3">Platform, plug-in and software updates.</p>
            </div>
         </div>
      </div>
   </div>
</div>
);
};
export default WebMaintenance;