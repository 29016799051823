import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
const Sidebar = ({ isOpen, onClose }) => {
  const currentPath = window.location.pathname;
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="row">
        <div className="col-12 col-md-6"></div>
        <div className="col-12 col-md-6">
          <div className="scroll-container">
            <div className="text-end">
              <button
                className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary close-btn css-1ujsas3"
                tabIndex="0"
                type="button"
                onClick={onClose}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  className="text-white"
                  height="30"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                </svg>
                <span className="MuiTouchRipple-root css-w0pj6f"></span>
              </button>
            </div>
            <div className="nav-menu">
              <div className="menu-label">Trading Solutions</div>
              <NavLink
                exact
                to="/hosting-service"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Hosting Service
              </NavLink>
              <NavLink
                exact
                to="/trading-platform"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Trading Platform
              </NavLink>
              <NavLink
                exact
                to="/payment-solution"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Payment Solution
              </NavLink>
              <NavLink
                exact
                to="/aggregated-liquidity"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Aggregated Liquidity
              </NavLink>
              <div className="menu-label">Site Development</div>
              <NavLink
                exact
                to="/brand-identity"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Brand Identity
              </NavLink>
              <NavLink
                exact
                to="/web-design"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Web Design
              </NavLink>
              <NavLink
                exact
                to="/web-development"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Web Development
              </NavLink>
              <NavLink
                exact
                to="/digital-marketing"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Digital Marketing
              </NavLink>
              <NavLink
                exact
                to="/web-maintenance"
                className="d-flex flex-row nav-link"
                activeClassName="active"
                onClick={onClose}
              >
                Web Maintenance
              </NavLink>
              <div className="d-flex gap-2 gap-lg-5 ">
                <NavLink
                  exact
                  to="/contact-us"
                  className="nav-link"
                  activeClassName="active"
                  onClick={onClose}
                >
                  <button
                    className="text-white fw-bold fs-5 css-1ujsas3 px-0"
                    type="button"
                  >
                    Contact Us
                  </button>
                </NavLink>
                <NavLink
                  exact
                  to="/about-us"
                  className="nav-link"
                  activeClassName="active"
                  onClick={onClose}
                >
                  <button
                    className="text-white fw-bold fs-5 css-1ujsas3 px-0"
                    type="button"
                  >
                    About Us
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
