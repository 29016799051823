import React, { useState } from "react";
import axios from "axios";
import { timeZones } from "./Timezones"; // Assuming you have imported timeZones correctly

const contactTimes = [
  { label: "6AM - 9AM", value: "6AM - 9AM" },
  { label: "9AM - 12PM", value: "9AM - 12PM" },
  { label: "12PM - 3PM", value: "12PM - 3PM" },
  { label: "3PM - 6PM", value: "3PM - 6PM" },
  { label: "6PM - 9PM", value: "6PM - 9PM" },
  { label: "9PM - 12AM", value: "9PM  - 12AM" },
];

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    timeZone: "",
    contactTime: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://www.fathena.com/php/contactmail.php",
        formData
      );

      if (response.status === 200) {
        alert(
          "Thank you! Your form has been submitted successfully. We will contact you soon."
        );
      } else {
        alert(
          "Something went wrong. There is a technical error. Please try submitting again later."
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error
    }
  };

  return (
    <div className="container">
      <form
        className="form-section"
        id="contactus-form-section"
        onSubmit={handleSubmit}
      >
        <div className="row">
          <div className="col-12 col-lg-6 col-md-6 mb-4">
            <label className="fw-medium fs-5 pb-2" htmlFor="firstName">
              First Name
            </label>
            <input
              type="text"
              className="contact_form_input w-100"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="First Name"
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-md-6 mb-4">
            <label className="fw-medium fs-5 pb-2" htmlFor="lastName">
              Last Name
            </label>
            <input
              type="text"
              className="contact_form_input w-100"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-md-6 mb-4">
            <label className="fw-medium fs-5 pb-2" htmlFor="phoneNumber">
              Phone Number
            </label>
            <input
              type="text"
              className="contact_form_input w-100"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-md-6 mb-4">
            <label className="fw-medium fs-5 pb-2" htmlFor="timeZone">
              Your Time Zone
            </label>
            <select
              id="timeZone"
              className="contact_form_input w-100"
              name="timeZone"
              placeholder="Timezone"
              value={formData.timeZone}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Time Zone</option>
              {timeZones.map((zone, index) => (
                <option key={index} value={zone.value}>
                  {zone.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-lg-6 col-md-6 mb-4">
            <label className="fw-medium fs-5 pb-2" htmlFor="contactTime">
              Best Time to Reach You
            </label>
            <select
              id="contactTime"
              className="contact_form_input w-100"
              name="contactTime"
              placeholder="Contact Time"
              value={formData.contactTime}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Contact Time</option>
              {contactTimes.map((time, index) => (
                <option key={index} value={time.value}>
                  {time.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 mb-4">
            <label className="fw-medium fs-5 pb-2" htmlFor="message">
              Comment or Message
            </label>
            <textarea
              className="contact_form_input w-100"
              id="message"
              name="message"
              placeholder="Inquiries"
              value={formData.message}
              onChange={handleInputChange}
              rows="4"
              cols="62"
              required
            />
          </div>
        </div>
        <button className="fathena-btn css-1hw9j7s" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
