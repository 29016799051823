import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import footerimg from "../assets/images/footer.jpg";
const Footer = () => {
  return (
    <div className="footer text-left bg-white text-lg-start">
      <div className="container">
        <div className="row py-3 align-items-center">
          <div className="col-12 col-lg-2 mb-4 mb-lg-0">
            <div className="footer-logo">
              <a href="/">
                <img className="w-100 h-auto pb-lg-2" src={footerimg} alt="" />
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-5 mb-4 mb-lg-0"></div>
          <div className="col-12 col-lg-5 footer-text">
            <div className="ms-0 ms-lg-auto ">
              <div className="d-flex gap-5 justify-content-center justify-content-lg-start pb-3">
                <a className="nav-link-foot" href="/about-us">
                  <b>About Us</b>
                </a>
                <a className="nav-link-foot" href="/contact-us">
                  <b>Contact Us</b>
                </a>
              </div>
            </div>
            <p>
              <b> Athena Technology Limited HQ </b> 71-75 Shelton Street, Covent
              Garden, London, WC2H 9JQ, United Kingdom. Tel:{" "}
              <a href="tel:+442080899460">+44 20 8089 9460</a> | Email:{" "}
              <a href="mailto:admin@fathena.com">admin@fathena.com</a>
            </p>
            <p>
              <b> Athena Technology Korea </b> 35 HanNam-Daero, YongSan-Gu,
              Seoul, South Korea. Tel:{" "}
              <a href="tel:+8227901323">+82.2.790.1323</a> | Email:{" "}
              <a href="mailto:support@fathena.com">support@fathena.com</a>
            </p>
          </div>
        </div>
        <div className="text-left text-lg-start py-3 ">
          <p>
            {" "}
            <b> Disclaimer: </b> Athena Technology operates solely as an
            information technology company. We expressly disclaim any provision
            of financial services or acceptance of investments. The contents of
            this site are not designed for distribution to, or use by, any
            individuals or entities in any jurisdiction or country where such
            distribution or use would contravene local laws or regulations.
            Athena Technology is not authorized to engage in or offer financial
            services or investment opportunities.
          </p>
          <p
            style={{
              textAlign: "center",
              color: "rgb(183, 192, 213)",
              marginBottom: "20px",
            }}
          >
            {" "}
            © Copyright 2024-25. Athena. All Rights Reserved |
            <Link to="/terms-of-use"> Terms of use | </Link>
            <Link to="/privacy-policy"> Privacy Policy</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
