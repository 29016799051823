import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeHeroVideo from "../assets/images/HomeHeroVideo.mp4";
import CEO from "../assets/images/aboutus/CEO.png";
import aboutus3 from "../assets/images/aboutus/aboutus3.png";
import slide1 from "../assets/images/aboutus/slide1.jpg";
import slide2 from "../assets/images/aboutus/slide2.jpg";
import slide3 from "../assets/images/aboutus/slide3.jpg";
import slide4 from "../assets/images/aboutus/slide4.png";
import culture from "../assets/images/EmpoweringFutur.png";
import { Helmet } from "react-helmet";

const slides = [
  { src: slide1, alt: "Slide 1" },
  { src: slide2, alt: "Slide 2" },
  { src: slide3, alt: "Slide 3" },
  { src: slide4, alt: "Slide 4" },
];
const AboutUs = () => {
  useEffect(() => {
    const videoElement = document.getElementById("canvabannervideo");
    if (videoElement) {
      videoElement
        .play()
        .then(() => {
          // Autoplay started successfully
        })
        .catch((error) => {
          // Autoplay was prevented
          console.error("Autoplay was prevented: ", error);
        });
    }
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [secExpanded, setsecExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const toggleSecExpanded = () => {
    setsecExpanded(!secExpanded);
  };

  const handleReadMoreClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <Helmet>
        <title>
          About Athena Technology | Leading Financial Technology Solutions
        </title>
        <meta
          name="description"
          content="Learn about Athena, a premier provider of financial technology solutions. Discover our mission, values, and the expertise that drives our innovative services in trading platforms, payment solutions, web development, and more."
        />
        <meta
          name="keywords"
          content="about Athena Technology, financial technology solutions, trading platform provider"
        />
      </Helmet>
      <div className=" blue-bannar AboutUsImg" style={{}}>
        <div className="container-fluid AboutUs1stBanner">
          <div className="row">
            <div className=" col-12 col-md-5 aboutus1stcontent">
              <h1>
                Pioneering the Future <br /> of Trading
              </h1>
              <p>
                Simple, Cost-Efficient, Fast—Crafting <br />
                the Trading Experience.
              </p>
              <btn className="ReadMorebtn" onClick={toggleExpanded}>
                <b>{expanded ? "View Less" : "Read More About Us"}</b>
              </btn>
            </div>
            {expanded && (
              <div className="col-12 col-md-7 aboutus1stcontent">
                <p style={{ fontSize: "20px" }} className="aboutus_content">
                  Athena Technology, established in 2019, strives to
                  revolutionize the online trading experience by focusing on
                  simplicity, cost-efficiency, and speed. These principles guide
                  the development of each tool and service, aiming to provide
                  top-tier trading solutions. The company culture prioritizes
                  these values, fostering an environment that encourages
                  innovation and focuses on enhancing user experience. Athena
                  Technology is dedicated to continuous improvement, ensuring
                  their trading platforms are industry-leading and empowering
                  traders to maintain a competitive edge. Since its founding,
                  Athena has positioned itself as a pioneer in the trading
                  sector, continuously pushing the boundaries of what is
                  possible in online trading.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row aboutus2ndcont">
          <div className="col-12 col-md-6  aboutus2ndcontentAfter">
            <h1 style={{ color: "rgb(34, 34, 34)" }}>
              <b>Welcome to Athena Technology</b>
            </h1>
            <p style={{ paddingtop: "15px" }}>
              <b> Dear Partner and Shareholder </b>
            </p>
            <p>
              At Athena Technology, we harness a blend of diverse talents and
              innovative ideas. Our team, which boasts a wealth of industry
              expertise and a robust passion for pragmatic innovation, is the
              cornerstone of our mission. Their unwavering dedication and
              commitment are what propel us daily toward redefining the
              capabilities of a trading platform.
            </p>
            <p>
              Our approach is grounded in a simple philosophy: trading platforms
              should be accessible, affordable, and swift, enabling traders to
              execute their strategies effectively and without undue burden.
              This philosophy guides every decision we make and every feature we
              develop, ensuring that we remain leaders in the industry.
            </p>
            <p>
              As we continue to move forward, our dedication to driving
              innovation and setting new standards in trading technology remains
              unwavering. We are committed to maintaining the core values of
              simplicity, cost-efficiency, and speed in all our developments.
            </p>
            <p>
              I warmly invite you to join us on this ongoing journey, to be a
              part of the transformative changes that are shaping the future of
              trading. Together, we can redefine what it means to trade
              efficiently and effectively in the digital age.
            </p>
            <p>
              Best regards,
              <br />
              <b>Edward HyungJin Jung</b>
              <br />
              Founder &amp; CEO, Athena Technology
            </p>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center AboutUsC2Img">
            <div className="AboutUsbox"></div>
            <div className="aboutus2ndcontent">
              <img src={CEO} alt="CEO image" style={{ width: "96%" }} />
            </div>
          </div>
          <div className="col-12 col-lg-6 pe-0 pe-lg-4 mt-4">
            <h5 className="fw-bold" style={{ color: "rgb(43, 166, 255)" }}>
              CEO Profile Overview:
            </h5>
            <p>
              Edward HyungJin Jung, an NYU alum, transitioned from Wall Street
              to pioneering AI in trading with his creation of a "black box"
              system. This innovation led him to found Athena Technology in
              2019, where he combines deep industry knowledge with advanced
              technology to enhance digital trading platforms.
            </p>
          </div>
          <div className="col-12 col-lg-6 mt-4  AboutUsC2Img2 ">
            <h5 style={{ color: "rgb(43, 166, 255)" }}>
              <b>Educational &amp; Career Milestones:</b>
            </h5>
            <p>
              1999: Stockbroker at Merrill Lynch.
              <br />
              2000: Currency Trader at Citi Group New York.
              <br />
              2005: Principal Manager at Nara New York Wealth Management.
              <br />
              2010: Principal Partner of FSICM New York Hedge Fund.
              <br />
              2019: Founder and CEO of Athena Technology,
            </p>
          </div>
        </div>
      </div>
      <div className="AboutUsImg2 ">
        <div className="container ">
          <div className="row aboutus2ndcont">
            <div className="col-12 col-md-6 aboutus3rdcontentmod ">
              <div>
                <h1>
                  <b>Our Dynamic Team</b>
                </h1>
                <h5>
                  At the heart of Athena's success is its team. A vibrant mix of
                  seasoned IT and financial professionals along with fresh,
                  young talent in both fields ensures a balanced approach to our
                  mission. This blend of experience and fresh perspectives makes
                  us agile, innovative, and always ready to adapt to the
                  evolving needs of the trading world.
                  <br />{" "}
                  <span className="ReadCeobtn">
                    <btn className="ReadMorebtn" onClick={toggleSecExpanded}>
                      <b>{secExpanded ? "View Less" : "Read More About Us"}</b>
                    </btn>
                  </span>
                </h5>
              </div>
            </div>
            {secExpanded && (
              <div className="col-12 col-md-6 aboutus3rdcontentmod ">
                <p style={{ fontSize: "20px" }}>
                  Diversity in Expertise Global Talent Pool: Our team members
                  hail from all corners of the globe, bringing a wealth of
                  diverse perspectives and ideas. Multidisciplinary
                  Professionals: From seasoned developers to creative marketers,
                  our team comprises experts across various fields, all united
                  by a common goal. Leadership and Vision Experienced
                  Leadership: Guided by a leadership team with years of industry
                  experience, we navigate the complexities of the fintech world
                  with confidence and foresight. Collaborative Environment: Our
                  leaders are approachable and collaborative, fostering a
                  culture of open communication and shared vision. Innovation
                  and Creativity Creative Freedom: We encourage our team members
                  to think creatively, explore new ideas, and challenge the
                  status quo. Problem Solvers: Innovation isn’t just about
                  technology; it’s about finding solutions to complex
                  challenges. Our team excels in this arena. Growth and
                  Development Learning Culture: We invest in the continuous
                  development of our team, offering training and opportunities
                  to advance their skills. Career Advancement: At Athena, career
                  progression is not just a possibility; it’s a priority. We
                  support our team members in their professional journeys. Team
                  Spirit Collaborative Projects: Working on projects
                  collaboratively, we leverage each other’s strengths and learn
                  from different viewpoints. Team Building: Regular
                  team-building activities and social events foster a sense of
                  camaraderie and unity.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container Section4AboutUs">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            {" "}
            <img className="about4Img" src={aboutus3} alt="Team image" />
          </div>
          <div className="col-12 col-md-6 AboutUsSecttion4Content">
            <h1>Trading, Transformed</h1>
            <h3>Swift, Smart, Superior with Athena.</h3>
            <br />
            <p>
              <b>Mission:</b> To redefine the trading experience by offering a
              next-generation platform that’s not just swift and user-friendly,
              but also enhanced with the intelligence of AI, ensuring optimized
              trading outcomes.
            </p>
            <br />
            <p>
              <b>Vision:</b> We envision a world where trading systems are not
              only reliable but also continuously advancing with technology. Our
              dream is to be the first choice for traders globally, providing
              them with a platform that’s ahead of its time.
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="AboutUsLAstSectionText">
          <h1>Company Culture</h1>
          <p>Innovation, Integrity, Inclusivity: The Pillars of Our Culture</p>
        </div>
        <div className="row py-5 m-0" style={{ backgroundColor: "black" }}>
          <div className="col-12 col-lg-6 py-5 ">
            {/* <img className="about5Img" src={culture} alt="Team image" /> */}
            <video
              autoPlay
              muted
              loop
              id="canvabannervideo"
              className="canvabannervideo"
            >
              <source src={HomeHeroVideo} />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-12 col-lg-6">
            <div>
              <h5 className="fw-bold SectionTitleLastAbout4">
                Innovation at Every Step
              </h5>

              <ol className="custom-list AboutSectionListp">
                <li>
                  Creativity and Problem-Solving: Innovation is in our DNA. We
                  encourage creative thinking and problem-solving to overcome
                  challenges and deliver cutting-edge solutions.
                </li>
                <li>
                  {" "}
                  Continuous Improvement: We believe there’s always room for
                  growth. Athena fosters a culture of continuous improvement,
                  where learning and adapting are part of our everyday work.
                </li>
              </ol>
            </div>
            <div>
              <h5 className="fw-bold SectionTitleLastAbout4">
                Integrity and Transparency
              </h5>
              <ol className="custom-list AboutSectionListp">
                <li>
                  {" "}
                  Ethical Standards: Integrity is at the core of all we do. We
                  uphold the highest standards of professionalism and ethics in
                  our work and interactions.
                </li>
                <li>
                  {" "}
                  Open Communication: Transparent communication forms the basis
                  of our operations. We believe in open dialogues, where every
                  voice is heard and valued.
                </li>
              </ol>
            </div>
            <div>
              <h5 className="fw-bold SectionTitleLastAbout4">
                Diversity and Inclusion
              </h5>
              <ol className="custom-list AboutSectionListp">
                <li>
                  {" "}
                  A Melting Pot of Talents: Athena is proud of its diverse
                  workforce. We bring together people from various backgrounds,
                  each adding unique value to our collective goals.
                </li>
                <li>
                  {" "}
                  Inclusivity: We are committed to creating an inclusive
                  environment where everyone feels respected, regardless of
                  their background or identity
                </li>
              </ol>
            </div>
            <div>
              <h5 className="fw-bold SectionTitleLastAbout4">
                Empowering Our People
              </h5>
              <ol className="custom-list AboutSectionListp">
                <li>
                  {" "}
                  Professional Growth: At Athena, career development is a
                  priority. We provide opportunities for learning, mentorship,
                  and advancement.
                </li>
                <li>
                  {" "}
                  Recognition and Reward: We recognize and reward the hard work
                  and achievements of our team members, celebrating individual
                  and collective successes.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
