import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Backgroundliqidity from '../assets/images/Background/liqidity.jpg';
import image1 from '../assets/images/Liquidity/image1.png';
import image2 from '../assets/images/Liquidity/image2.png';
import image3 from '../assets/images/Liquidity/image3.png';
import image4 from '../assets/images/Liquidity/image4.png';
import image5 from '../assets/images/Liquidity/image5.png';
import image6 from '../assets/images/Liquidity/image6.png';
import image7 from '../assets/images/Liquidity/image7.png';
import image8 from '../assets/images/Liquidity/image8.png';
import image9 from '../assets/images/Liquidity/image9.png';
import image10 from '../assets/images/Liquidity/image10.png';
import image11 from '../assets/images/Liquidity/image11.png';
import image12 from '../assets/images/Liquidity/image12.png';
import image13 from '../assets/images/Liquidity/image13.png';
import image14 from '../assets/images/Liquidity/image14.png';
import image15 from '../assets/images/Liquidity/image15.png';
import image16 from '../assets/images/Liquidity/image16.png';
import image17 from '../assets/images/Liquidity/image17.png';
import image18 from '../assets/images/Liquidity/image18.png';
import image19 from '../assets/images/Liquidity/image19.png';
import image20 from '../assets/images/Liquidity/image20.png';
import image21 from '../assets/images/Liquidity/image21.png';
import { Helmet } from 'react-helmet';
const AggregatedLiquidity = () => {
return(
<div>
<Helmet>
        <title>Aggregated Liquidity Solutions | Fathena.com</title>
        <meta name="description" content="Discover Aathena's aggregated liquidity solutions tailored for enhanced market depth and improved pricing in Forex, stocks, gold, and cryptos. Optimize your trading with our advanced liquidity aggregation technology." />
        <meta name="keywords" content="aggregated liquidity, Forex liquidity solutions, gold trading liquidity" />
      </Helmet>
   <div className="blue-bannar payment_banner" style={{backgroundImage: `url(${Backgroundliqidity})`}}>
      <div className="container text-start text-lg-center">
         <div className="row my-4 align-items-center ">
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-md-12 col-lg-8">
               <h1><b>Precision Liquidity,</b><br/>Optimized Outcomes</h1>
               <ul className="bannar-list mt-2 mt-lg-0 p-0">
                  <li>Customized Liquidity Pools | Competitive Quote Aggregation | Multi-Tiered Bid/Offer System | Liquidity Management | Enhancing Transaction Efficiency</li>
               </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
         </div>
         <div className="text-center"><img className="mt-3" alt=""/></div>
      </div>
   </div>
   <div className="container">
      <div className="row my-5 pt-lg-5 pt-1">
         <div className="col-12 col-md-10 ">
            <h1 className="section-title text-start text-larger p-0 pb-lg-2">Liquidity Management</h1>
            <h3 className="bluetitle my-4">Enhancing Transaction Efficiency</h3>
            <p>Effective liquidity management begins with a comprehensive analysis of liquidity quality. At Athena, we utilize this insight to optimize liquidity, aiming to minimize execution costs and enhance the quality of order settlements. Our strategic approach ensures that transactions are both cost-effective and executed with precision, supporting the overall financial health and operational efficiency of your business.</p>
         </div>
      </div>
      <div className="row">
         <div className="col-1 d-none d-md-block"></div>
         <div className="col-12 col-md-10">
            <h3 className="bluetitle text-black text-center fw-bold my-5">Grouping liquidity providers with similar behavior to optimize</h3>
            <div className="d-block d-md-none my-4 mb-5">
               <div className="mb-4">
                  <h2 className="fs-2">Spread</h2>
                  <p className="section-text2 text-black">The difference between the bid and offer prices.</p>
               </div>
               <div>
                  <h2 className="fs-2">Fill Ratio (%)</h2>
                  <p className="section-text2 text-black">Amount of order settled without rejections and requotes.</p>
               </div>
            </div>
            <img className="w-100 h-auto" src={image1} alt=""/>
            <div className="d-block d-md-none my-4 mt-5">
               <div className="mb-4">
                  <h2 className="fs-2">Cost of Rejects</h2>
                  <p className="section-text2 text-black">The cost associates with the immediate price change following a rejection.</p>
               </div>
               <div>
                  <h2 className="fs-2">Execution time</h2>
                  <p className="section-text2 text-black">Amount of time for an order to be accepted and filled in milliseconds.</p>
               </div>
            </div>
         </div>
         <div className="col-1 d-none d-md-block"></div>
      </div>
   </div>
   <div className="container">
      <div className="row my-5 pb-1 pb-lg-5">
         <h3 className="section-title my-4 my-lg-5 pt-1 pt-lg-5">Major liquidities providers in our ecosystem</h3>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image2} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image3} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image4} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image5} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image6} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image7} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image8} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image9} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image10} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image11}  alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image12} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image13} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image14} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image15} alt=""/></div>
            </div>
         </div>
         <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div className="card p-5 border-0 rounded-0 h-100" style={{background: "rgb(243, 245, 248)"}}>
               <div className="d-flex justify-content-center align-items-center h-100"><img src={image16} alt=""/></div>
            </div>
         </div>
      </div>
   </div>
   <div>
      <div className="container">
         <div className="row my-5">
            <div className="col-12 col-md-10 col-lg-6">
               <h1 className="section-title text-start text-larger">Infrastructure</h1>
               <h3 className="section-title text-start">Low latency connectivity within Equinix data centers</h3>
               <h3 className="bluetitle my-4">Minimize trade requests up to a hundredth of a millisecond.</h3>
            </div>
            <div className="col-12 mt-4">
               <img className="w-100 h-auto d-none d-lg-block" src={image17} alt=""/>
               <img className="w-100 h-auto d-block d-lg-none" src={image18} alt=""/>
               <div className="d-block d-lg-none">
                  <div className="check-p mt-5">
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Singapore
                     </p>
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Sydney
                     </p>
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Miami
                     </p>
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        New York
                     </p>
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Silicon Valley
                     </p>
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Washington D.C.
                     </p>
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        Amsterdam
                     </p>
                     <p className="align-items-center text-black d-flex gap-2 pb-0 fs-5">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="mb-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(85, 132, 255)"}}>
                           <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                        </svg>
                        London
                     </p>
                  </div>
               </div>
               <p className="section-text2 my-4 my-lg-5">Low latency connectivity to the world’s largest financial ecosystem within Equinix data centers, enabling us to minimize trade requests up to a hundredth of a millisecond with physical proximity to trade servers of major liquidity providers.</p>
            </div>
         </div>
      </div>
   </div>
   <div className="container">
      <div className="row">
         <div className="col-1 d-none d-md-block"></div>
         <div className="col-12 col-md-10">
            <h3 className="bluetitle text-black text-center fw-bold my-4 my-lg-5 liquidity-title">Benefits of Athena liquidity aggregation</h3>
            <p className="d-block d-lg-none section-text2 text-center my-4">Aggregate as many liquidity providers as you need for the best pricing</p>
            <p className="d-block d-lg-none section-text2 text-center">Generate aggregated market depth across any and all liquidity providers</p>
            <img className="w-100 h-auto d-none d-lg-block" src={image19} alt=""/>
            <img className="w-100 h-auto d-block d-lg-none my-5" src={image20} alt=""/>
            <p className="d-block d-lg-none section-text2 text-center">Receive and offer deep liquidity during all market conditions</p>
         </div>
         <div className="col-1 d-none d-md-block"></div>
      </div>
   </div>
   <div className="container">
      <div className="row">
         <div className="col-1 d-none d-md-block"></div>
         <div className="col-12 col-md-10">
            <h3 className="bluetitle text-black text-center fw-bold my-4 my-lg-5 liquidity-title">Aggregate liquidity with MT4 and MT5 <br/>Gateway</h3>
            <img className="w-100 h-auto py-5" src={image21} alt=""/>
         </div>
         <div className="col-1 d-none d-md-block"></div>
      </div>
   </div>
</div>  
)
};

export default AggregatedLiquidity;