// TimeZones.js
export const timeZones = [
    { label: "International Date Line West", value: "GMT -12:00" },
    { label: "Samoa Standard Time", value: "GMT -11:00" },
    { label: "Hawaii-Aleutian Standard Time", value: "GMT -10:00" },
    { label: "Alaska Standard Time", value: "GMT -09:00" },
    { label: "Pacific Standard Time (USA, Canada)", value: "GMT -08:00" },
    { label: "Mountain Standard Time (USA, Canada)", value: "GMT -07:00" },
    { label: "Central Standard Time (USA, Canada, Mexico)", value: "GMT -06:00" },
    { label: "Eastern Standard Time (USA, Canada)", value: "GMT -05:00" },
    { label: "Atlantic Standard Time (Canada)", value: "GMT -04:00" },
    { label: "Bolivia Time", value: "GMT -04:00" },
    { label: "Argentina Time", value: "GMT -03:00" },
    { label: "Brazil Time (East)", value: "GMT -03:00" },
    { label: "Uruguay Time", value: "GMT -03:00" },
    { label: "South Georgia Standard Time", value: "GMT -02:00" },
    { label: "Azores Time", value: "GMT -01:00" },
    { label: "Greenwich Mean Time", value: "GMT +00:00" },
    { label: "Western European Time", value: "GMT +00:00" },
    { label: "Central European Time", value: "GMT +01:00" },
    { label: "West Africa Time", value: "GMT +01:00" },
    { label: "Eastern European Time", value: "GMT +02:00" },
    { label: "Central Africa Time", value: "GMT +02:00" },
    { label: "Eastern African Time", value: "GMT +03:00" },
    { label: "Arabian Standard Time", value: "GMT +03:00" },
    { label: "Iran Standard Time", value: "GMT +03:30" },
    { label: "Moscow Standard Time", value: "GMT +03:00" },
    { label: "Gulf Standard Time", value: "GMT +04:00" },
    { label: "Pakistan Standard Time", value: "GMT +05:00" },
    { label: "Indian Standard Time", value: "GMT +05:30" },
    { label: "Nepal Time", value: "GMT +05:45" },
    { label: "Bangladesh Standard Time", value: "GMT +06:00" },
    { label: "Myanmar Time", value: "GMT +06:30" },
    { label: "Indochina Time", value: "GMT +07:00" },
    { label: "China Standard Time", value: "GMT +08:00" },
    { label: "Japan Standard Time", value: "GMT +09:00" },
    { label: "Korean Standard Time", value: "GMT +09:00" },
    { label: "Australian Western Standard Time", value: "GMT +08:00" },
    { label: "Australian Central Standard Time", value: "GMT +09:30" },
    { label: "Australian Eastern Standard Time", value: "GMT +10:00" },
    { label: "Chamorro Standard Time", value: "GMT +10:00" },
    { label: "New Zealand Standard Time", value: "GMT +12:00" },
    { label: "Fiji Time", value: "GMT +12:00" },
    { label: "Tonga Time", value: "GMT +13:00" },
    { label: "Line Islands Time", value: "GMT +14:00" }
  ];
  
