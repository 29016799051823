import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy | Fathena.com</title>
        <meta
          name="description"
          content="Read Athena's Privacy Policy to understand how we protect your personal information. Learn about our data collection, usage, and protection practices to ensure your privacy and security."
        />
        <meta name="keywords" content="Aathena privacy policy" />
      </Helmet>
      <div class="container">
        <div class="row">
          <div class="col-12 PrivacyPolicy">
            <br />
            <br />
            <h1>PRIVACY POLICY</h1>
            <p>
              At Fathena.com, operated by Athena Technology Limited, we
              recognize the importance of your privacy and are committed to
              protecting your personal data. This policy outlines our practices
              for collecting, using, and safeguarding your data and complies
              with the General Data Protection Regulation (GDPR) and local
              applicable legislation.
            </p>
            <h1>
              <u>Who We Are</u>
            </h1>
            <p>
              Fathena.com is operated by Athena Technology Limited, a company
              dedicated to providing trading solutions for institutions,
              registered in England under company number 13275910, with our main
              business office at 71-75 Shelton Street, Covent Garden, London
              WC2H 9JQ, United Kingdom. Contact us via email at{" "}
              <a href="mailto:admin@fathena.com">admin@fathena.com</a> or call
              us at <a href="tel:+442080899460">+44 20 8089 9460</a>.
            </p>
            <h1>
              <u>Data Collections and Processing</u>
            </h1>
            <p>
              At Fathena.com, we are committed to providing a secure and
              efficient service environment by collecting a range of personal
              data essential for our operational, compliance, and improvement
              purposes. Here’s an expanded overview of the types and purposes of
              the data we collect:
            </p>
            <h2>Types of Data Collected:</h2>
            <p>
              <span>Identity Data:</span> This includes your first and last
              name, date of birth, and gender, along with copies of your
              identity documents such as passports and driver’s licenses.
              Collecting this data helps us to verify your identity, which is
              crucial for maintaining the security and integrity of our
              services, and to ensure that our services are not misused.
            </p>
            <p>
              <span>Contact Data: </span> We collect your email addresses,
              telephone numbers, and billing addresses. This data is essential
              for communicating with you regarding your account, for billing and
              invoicing purposes, and to provide support whenever you need help
              with our services.
            </p>
            <p>
              <span>Technical Data:</span> This encompasses data collected from
              the devices you use to access our services, such as IP addresses,
              login data, browser types and versions, time zone settings, and
              location data. Collecting this information helps us to diagnose
              issues with our services, enhance the security of our website, and
              improve the overall user experience by optimizing our site
              according to the technological attributes of our users.
            </p>
            <p>
              <span>Usage Data:</span> Information on how you use our site,
              including the pages you visit, the links you click, and the
              frequency of your access, allows us to understand user behavior on
              our platform better. This data helps us to tailor our services to
              better meet the needs of our customers and to prioritize
              enhancements based on user interaction patterns.
            </p>
            <p>
              <span>Location Data:</span> We collect real-time geographical
              location data when you interact with our website. This data is
              used to provide localized content, such as offering services
              appropriate to your jurisdiction and complying with local laws and
              regulations.
            </p>
            <p>
              <span>KYC Data:</span> As part of our “Know Your Customer”
              obligations, we collect copies of identity documents, recent
              utility bills, and tax identification numbers. This information is
              critical for legal compliance, preventing fraud, and ensuring that
              our services are not used for illegal activities.
            </p>
            <h1>
              <u>Use of Cookies and Similar Technologies:</u>
            </h1>
            <p>
              We employ cookies and similar technologies to enhance your
              experience on our website. These tools are crucial for remembering
              your preferences, personalizing content to your interests, and
              making your interactions with our site smoother and more
              efficient. Cookies help us track site usage patterns and identify
              areas for improvement.
            </p>
            <h1>
              <u>How We Use Your Data</u>
            </h1>
            <p>
              At Fathena.com, we process your personal data with a commitment to
              the General Data Protection Regulation (GDPR) principles of
              lawfulness, fairness, and transparency. Here is an expanded view
              of the specific purposes for which we use your data:
            </p>
            <p>
              <span>To Fulfill Our Contract with You:</span> We use your
              personal data to execute and manage your account as per the
              contractual agreement between us. This includes processing
              transactions, maintaining accurate records of your trading
              activity, and providing the financial services you have requested.
            </p>
            <p>
              <span>To Comply with Legal and Regulatory Obligations:</span> Your
              personal data helps us adhere to legal standards and regulatory
              requirements pertinent to our financial services. Compliance
              involves reporting to regulatory bodies, conducting audits, and
              fulfilling tax-related duties.
            </p>
            <h2>
              For Customer Support, Business Management, and Service
              Improvement:
            </h2>
            <p>
              <span>Customer Support:</span> We use your data to provide
              personalized support when you encounter issues or have questions
              about our services. This ensures effective and efficient
              resolution of your inquiries and technical problems.
            </p>
            <p>
              <span>Business Management: </span> Your data aids in the overall
              management of business operations including performance analysis,
              risk management, and strategic planning.
            </p>
            <p>
              <span>Service Improvement:</span> We analyze usage patterns and
              collect feedback to enhance the usability and features of our
              services. This continuous improvement process is vital for staying
              competitive and fulfilling user expectations.
            </p>
            <h1>
              <u>Data Security</u>
            </h1>
            <p>
              We take the security of your personal data very seriously at
              Fathena.com. Here’s how we protect your information:
            </p>
            <p>
              <span>Encryption and Pseudonymization:</span> We use advanced
              encryption techniques to secure data transmissions and store
              information securely. Pseudonymization minimizes the risks by
              ensuring that personal data cannot be attributed to a specific
              data subject without the use of additional information.
            </p>
            <p>
              <span>Strict Access Controls:</span> Access to personal data is
              strictly controlled and limited to employees who need the
              information to perform their job functions. We employ role-based
              access controls that ensure only authorized personnel can access
              sensitive data.
            </p>
            <p>
              <span>Security Protocols:</span> Our systems are equipped with
              state-of-the-art security measures, including firewalls, intrusion
              detection systems, and regular security audits. These protocols
              help prevent unauthorized access, use, or disclosure of your
              personal data.
            </p>
            <h1>
              <u>Data Retention</u>
            </h1>
            <p>
              Our data retention policy is designed to ensure that personal data
              is not kept longer than necessary. We adhere to the following
              principles:
            </p>
            <p>
              <span>Retention According to Purpose:</span> We retain your
              personal data only for as long as necessary to fulfill the
              purposes for which it was collected, including for the purposes of
              satisfying any legal, accounting, or reporting requirements.
            </p>
            <p>
              <span>Secure Deletion:</span> Once the retention period expires
              and personal data is no longer needed, it is securely deleted or
              anonymized. We ensure that data disposal methods meet security
              standards and prevent data from being reconstructed or read.
            </p>
            <p>
              <span>Review and Adjustment of Retention Periods:</span> We
              regularly review our data retention periods based on business
              needs and legal requirements. Adjustments are made to ensure
              compliance with applicable laws and the minimization of data
              storage.
            </p>
            <h1>
              <u>Your Rights</u>
            </h1>
            <p>
              Under the General Data Protection Regulation (GDPR), you have
              several significant rights regarding your personal data. These
              rights are intended to give you greater control over your personal
              information:
            </p>
            <p>
              <span>Right to Access: </span> You have the right to request a
              copy of the personal data we hold about you, ensuring you are
              aware of and can verify the lawfulness of the processing.
            </p>
            <p>
              <span>Right to Rectification:</span> You can request that any
              incomplete or inaccurate data we hold about you is corrected
              promptly.
            </p>
            <p>
              <span>Right to Erasure (‘Right to be Forgotten’):</span> You may
              request the deletion or removal of personal data where there is no
              compelling reason for its continued processing.
            </p>
            <p>
              <span>Right to Restrict Processing:</span> You have the right to
              block or suppress processing of your personal data, which means we
              can store your data but not further process it.
            </p>
            <p>
              <span>Right to Data Portability:</span> This allows you to obtain
              and reuse your personal data for your own purposes across
              different services. It allows you to move, copy, or transfer
              personal data easily from one IT environment to another in a safe
              and secure way, without hindrance to usability.
            </p>
            <p>
              <span></span>
            </p>
            <p>
              <span>
                Rights Related to Automated Decision Making Including Profiling:{" "}
              </span>{" "}
              You have the right not to be subject to a decision based solely on
              automated processing, including profiling, which produces legal
              effects concerning you or similarly significantly affects you. To
              exercise any of these rights, please contact us using the contact
              details provided below. We will respond to your request in
              accordance with GDPR requirements.
            </p>
            <h1>
              <u>Changes to This Privacy Policy</u>
            </h1>
            <p>
              Our Privacy Policy may be updated from time to time to reflect
              changes in legal requirements, our processing activities, or other
              operational, legal, or regulatory reasons. We encourage you to
              review this policy periodically to stay informed about how we are
              protecting the personal data we collect. The date of the last
              modification is noted at the end of this policy.
            </p>
            <h1>
              <u> Contact Us</u>
            </h1>
            <p>
              If you have any questions, concerns, or complaints about this
              Privacy Policy or our handling of your personal data, or if you
              wish to exercise any of your rights as described above, please
              contact us at:
            </p>
            <p>
              <span>Email:</span>{" "}
              <a href="mailto:admin@fathena.com">admin@fathena.com</a>
            </p>
            <p>
              <span>Phone:</span>{" "}
              <a href="tel:+442080899460">+44 20 8089 9460</a>
            </p>
            <p>
              <span>Postal Address:</span> Athena Technology Limited, 71-75
              Shelton Street, Covent Garden, London WC2H 9JQ, United Kingdom.
            </p>
            <p>
              We are committed to working with you to obtain a fair resolution
              of any complaint or concern about privacy. If, however, you
              believe that we have not been able to assist with your complaint
              or concern, you have the right to make a complaint to the data
              protection authority of your country of residence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
