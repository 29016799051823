import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import webdev1 from "../assets/images/webdevelopment/webdev1.png";
import webdev3 from "../assets/images/webdevelopment/webdev3.png";
import { Helmet } from "react-helmet";
const WebDevelopment = () => {
  return (
    <div>
      <Helmet>
        <title>
          Advanced Web Development for Financial Platforms | Fathena.com
        </title>
        <meta
          name="description"
          content="Enhance your trading and brokerage business with Athena's cutting-edge web development services. Specializing in creating robust, secure, and scalable websites tailored for the finance industry."
        />
        <meta
          name="keywords"
          content="web development, Forex web development"
        />
      </Helmet>
      <div className="Brand_Identity_bg">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-xs-6 p-0">
              <div className="sm-bg">
                <h2 className="Brand_Heading">WEB &amp; APP</h2>
                <h2 className="Identity_Heading">Development</h2>
              </div>
              <div className="p-12">
                <div className="BrandIdentity_P">
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Seamless user experience
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Comprehensive competitor analysis
                  </p>
                  <p>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                    Experienced teams of quality assurance
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-around ">
          <div className="   col-xl-6 col-md-7 col-12  BRandIdentityBrandStrategy">
            <h2>Website Development</h2>
            <h3>
              Your website is the easiest and most powerful tool to introduce
              millions of people to your company.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 BrandIdentitys3_image webdevs2img">
            <img src={webdev1} alt="images" />
            <div className="d-block d-md-none">
              <p className="section-text2 text-black">
                From the very instant customers see your website, they are
                forming thoughts and opinions about your brand.
              </p>
              <p className="py-4 section-text2 text-black">
                That is why you need a professional and experienced site
                developer build your website into an informative, eye-catching,
                and seamless experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container webdevelopmentsection2">
        <div className="row d-flex justify-content-around ">
          <div className=" col-12 col-md-8 col-lg-6  webdevelopments3text">
            <h2> Web Development Process </h2>
          </div>
        </div>
        <div className="row WebDevelopmentSection3bg">
          <div className="col-12 ">
            <div className="brandidentitys4inner1">
              <h2>1. Learn </h2>
              <p>
                We start by getting to know you – your brand, needs, and goals –
                before deciding on an appropriate timeline.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 brandidentitys4inner2">
            <div className="brandidentitys4inner2c  ">
              <h2> 2. Analyze </h2>
              <p>
                Next, we study your business industry, competitors, and clients
                in order to develop an effective strategy.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="brandidentitys4inner2cr">
              <h2> 3. UX/UI </h2>
              <p>
                We work with you to decide on the key features and tools – as
                well as overall structure and contents – to ensure your clients
                will have a seamless experience.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2> 4. Visual Design</h2>
              <p>
                We create a tailor-made design plan that will convey the
                personality of your brand to customers in a creative and
                memorable way.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 Webdevsection3circle ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2>5. Rigorous testing </h2>
              <p>
                Finally, all plans are brought to life through our experienced
                coding team, and the speed, quality, and security are carefully
                examined to ensure the final product is nothing but perfect.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="BrandIdentitySectionBgimg  webdevelopmentbgection4">
        <div className="container Webdesignsectioncontainer1st WebDevSectioncontainer4">
          <div className="row d-flex justify-content-center flex-column align-content-around ">
            <div className="   col-xl-6 col-md-7 col-12  webDevelopmentSection4text ">
              <h2>Application Development</h2>
              <h3>
                Apps are one of the easiest ways to make your company a part of
                your consumers’ everyday lives.
              </h3>
            </div>
          </div>
          <div className="row  ">
            <div className=" col-12 col-md-6 webdevelopmentsection4textc1 d-flex align-items-center">
              <h3>
                It sets your brand apart from the rest and gives your audience a
                convenient way to stay connected to your business.
              </h3>
            </div>
            <div className=" col-12 col-md-6 webdevelopmentsection4textc2 d-flex align-items-center">
              <h3 className=" ">
                It strengthens trust with your consumers, and allows you to
                implement a wide variety of features to drive engagement and
                sales, such as notifications, location services, loyalty
                programs and more.
              </h3>
            </div>
            <div className="row d-flex justify-content-center">
              <div className=" col-12  col-md-8  webdevelopmentSection4img">
                <img src={webdev3} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container webdevelopmentsection5margin">
        <div className="row d-flex justify-content-around ">
          <div className=" col-12 col-md-8 col-lg-6  webdevelopments3text">
            <h2> Application Development Process </h2>
          </div>
        </div>
        <div className="row WebDevelopmentSection5bg">
          <div className="col-12 ">
            <div className="brandidentitys4inner1">
              <h2>1. Learn </h2>
              <p>
                We start by learning about your goals and objectives, business
                strategy, and what makes your brand unique.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 brandidentitys4inner2">
            <div className="brandidentitys4inner2c  ">
              <h2> 2. Research </h2>
              <p>
                Then, we research the industry, consumer base, and competitors
                to make carefully informed recommendations.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="brandidentitys4inner2cr">
              <h2> 3. Develop </h2>
              <p>
                Next, the app is developed for iOS and Android platforms and
                undergoes extensive client feedback and testing processes.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2>4. Communicate</h2>
              <p>
                Every step of the way, our team will communicate with you to
                ensure you are getting exactly what you want.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6  ">
            <div className="brandidentitys4inner1 brandidentitylastc4">
              <h2>5. Launch and Market</h2>
              <p>
                Once everything is flawless and you are pleased with the result,
                Athena will help market and launch the app.
              </p>
            </div>
          </div>
          <div className="col-12   ">
            <div className="brandidentitys4inner1 brandidentitylastc5">
              <h2>6. The result</h2>
              <p>
                A new, cutting-edge, powerful tool for you to grow your
                business.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebDevelopment;
