import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Backgroundpayment from "../assets/images/Background/payment.jpg";
import img1 from "../assets/images/payment/img1.png";
import img2 from "../assets/images/payment/img2.png";
import img3 from "../assets/images/payment/img3.png";
import img4 from "../assets/images/payment/img4.png";
import img5 from "../assets/images/payment/img5.png";
import img6 from "../assets/images/payment/img6.png";
import img7 from "../assets/images/payment/img7.png";
import img8 from "../assets/images/payment/img8.png";
import img9 from "../assets/images/payment/img9.png";
import img10 from "../assets/images/payment/img10.png";
import img11 from "../assets/images/payment/img11.png";
import img12 from "../assets/images/payment/img12.png";
import img13 from "../assets/images/payment/img13.png";
import img14 from "../assets/images/payment/img14.png";
import img15 from "../assets/images/payment/img15.png";
import img16 from "../assets/images/payment/img16.png";
import img17 from "../assets/images/payment/img17.png";
import img18 from "../assets/images/payment/img18.png";
import img19 from "../assets/images/payment/img19.png";
import arrow2 from "../assets/images/payment/arrow2.png";
import arrow3 from "../assets/images/payment/arrow3.png";
import InquiryForm from "./InquiryForm";
import { Helmet } from "react-helmet";

const PaymentSolution = () => {
  return (
    <div>
      <Helmet>
        <title>Secure Payment Solutions for Traders | Fathena.com</title>
        <meta
          name="description"
          content="Discover simple, fast, and cost-efficient trading with Athena. Specializing in robust platforms for Forex, stocks, gold, and cryptos, we help brokerage firms establish a strong market presence using our deep market knowledge and broad industry connections."
        />
        <meta
          name="keywords"
          content="Forex trading platform, gold trading, cryptocurrency trading, brokerage solutions,"
        />
      </Helmet>

      <div className="payment-bannar">
        <div
          className="blue-bannar payment_banner"
          style={{ backgroundImage: `url(${Backgroundpayment})` }}
        >
          <div className="container-fluid">
            <div className="row my-4 align-items-center ">
              <div className="col-12 col-lg-8">
                <div className="blue-banner-c-w">
                  <h1>
                    <b>Seamless Payment</b>
                    <br />
                    Solutions by Athena
                  </h1>
                  <ul className="bannar-list mt-2 mt-lg-0 p-0">
                    <li>
                      Local Bank Payments | Cryptocurrency <br />
                      Payments | Credit Card Payments | <br />
                      Efficiency and Security
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row my-5 seamles-sec pt-1 pt-lg-5">
            <div className="col-12 col-md-10 col-lg-8 pb-5 mb-0 mb-lg-5">
              <h1 className="section-title text-start p-0 text-larger">
                Seamless Payment Solutions by Athena
              </h1>
              <h3 className="bluetitle text-start my-4">
                Local Bank Payments | Cryptocurrency Payments | Credit Card
                Payments | Efficiency and Security Combined
              </h3>
              <p className="title-text">
                Athena provides a diverse range of payment solutions including
                local bank transfers, credit card transactions, and
                cryptocurrency options for real-time deposits and withdrawals.
                Our seamless integration ensures you can manage your funds
                efficiently and securely, giving you the flexibility to focus on
                what matters most.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <div className="card rounded-0 border-0">
                <img src={img1} alt="" />
                <h3 className="section-title">
                  It is free and there are no charge backs.
                </h3>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card rounded-0 border-0 ms-0 ms-lg-auto">
                <img className="adjust-seamless" src={img2} alt="" />
                <h3 className="section-title">
                  You can receive payments from anywhere in the world.
                </h3>
              </div>
            </div>
            <div className="col-12">
              <div className="card rounded-0 border-0 mx-auto">
                <img src={img3} alt="" />
                <h3 className="section-title">
                  Lower the barrier to accepting payments on the web.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5 mt-2 mt-lg-5 py-1 py-lg-5">
        <h3 className="section-title text-start">You can accept</h3>
        <div className="row px-4 px-lg-5">
          <div className="col-12 col-md-6 col-lg-4 mb-4 accept-card">
            <div
              className="card border-0 rounded-0 h-100 "
              style={{ backgroundcolor: "rgb(85, 132, 255)" }}
            >
              <div className="d-flex justify-content-between align-items-center p-4 h-100">
                <h4 className="card-text">Bitcoin</h4>
                <img src={img4} alt="Bitcoin" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4 accept-card">
            <div
              className="card border-0 rounded-0 h-100 "
              style={{ backgroundcolor: "rgb(85, 132, 255)" }}
            >
              <div className="d-flex justify-content-between align-items-center p-4 h-100">
                <h4 className="card-text">Bitcoin Cash</h4>
                <img src={img5} alt="Bitcoin Cash" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4 accept-card">
            <div
              className="card border-0 rounded-0 h-100 "
              style={{ backgroundcolor: "rgb(85, 132, 255)" }}
            >
              <div className="d-flex justify-content-between align-items-center p-4 h-100">
                <h4 className="card-text">Dai</h4>
                <img src={img6} alt="Dai" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4 accept-card">
            <div
              className="card border-0 rounded-0 h-100 "
              style={{ backgroundcolor: "rgb(85, 132, 255)" }}
            >
              <div className="d-flex justify-content-between align-items-center p-4 h-100">
                <h4 className="card-text">Ethereum</h4>
                <img src={img7} alt="Ethereum" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4 accept-card">
            <div
              className="card border-0 rounded-0 h-100 "
              style={{ backgroundcolor: "rgb(85, 132, 255)" }}
            >
              <div className="d-flex justify-content-between align-items-center p-4 h-100">
                <h4 className="card-text">Litecoin</h4>
                <img src={img8} alt="Litecoin" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4 accept-card">
            <div
              className="card border-0 rounded-0 h-100 no-image"
              style={{ backgroundcolor: "rgb(85, 132, 255)" }}
            >
              <div className="d-flex justify-content-between align-items-center p-4 h-100">
                <h4 className="card-text">
                  Contact us if you need solutions for other cryptocurrencies.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-6">
            <h1 className="section-title text-start text-larger p-0 m-0">
              How does it work
            </h1>
            <h3 className="bluetitle my-2 ">
              We don’t hold your crypto. You do. We give you all the benefits of
              a hosted platform while you maintain ownership.
            </h3>
          </div>
        </div>
        <div className="row mt-2 mb-5">
          <div className="col-12 col-md-6 position-relative mb-lg-0 mb-5">
            <div className="">
              <img className="w-100 h-auto" src={img9} alt="" />

              <h4
                className="col-lg-10 text-center text-position text-black "
                style={{}}
              >
                Client opens a trading account.
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 d-none d-lg-block">
            <div className="d-flex align-items-center  h-100">
              <img src={arrow2} alt="" />
            </div>
          </div>
          <div className="col-12 col-md-6 d-none d-lg-block">
            <div className="d-flex align-items-center  h-100 justify-content-end">
              <img src={arrow3} alt="" />
            </div>
          </div>
          <div className="col-12 col-md-6 position-relative mb-lg-0 mb-5">
            <div className="">
              <img className="w-100 h-auto" src={img10} alt="" />
              <h4
                className=" text-black position-absolute text-position"
                style={{}}
              >
                Athena creates a wallet for each trading account.
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 position-relative mb-lg-0 mb-5">
            <div className="">
              <img className="w-100 h-auto" src={img11} alt="" />
              <h4
                className=" text-black position-absolute text-position"
                style={{}}
              >
                Client sends cryptocurrency to the wallet.
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 d-none d-lg-block">
            <div className="d-flex align-items-center  h-100">
              <img src={arrow2} alt="" />
            </div>
          </div>
          <div className="col-12 col-md-6 mb-lg-0 mb-5 d-none d-lg-block"></div>
          <div className="col-12 col-md-6 position-relative mb-lg-0 mb-5">
            <div className="">
              <img className="w-100 h-auto" src={img12} alt="" />
              <h4
                className=" text-black position-absolute text-position"
                style={{}}
              >
                Athena credits the amount to the corresponding trading account.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5 py-1 py-md-5">
        <div className="row">
          <h3 className="section-title text-start">Security is our priority</h3>
          <h4>Our cryptocurrency trading partners.</h4>
          <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div
              className="card p-5 border-0 rounded-0 h-100"
              style={{ backgroundcolor: "rgb(243, 245, 248)" }}
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <img src={img13} alt="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div
              className="card p-5 border-0 rounded-0 h-100"
              style={{ backgroundcolor: "rgb(243, 245, 248)" }}
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <img src={img14} alt="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 partner-cards mt-4">
            <div
              className="card p-5 border-0 rounded-0 h-100"
              style={{ backgroundcolor: "rgb(243, 245, 248)" }}
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <img src={img15} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Bankbannar d-flex text-center justify-content-center align-items-center mb-5">
        <div className="">
          <h2 className="section-title text-white ">
            Athena credit card payment gateway
          </h2>
          <div className="mt-4 d-flex gap-5 justify-content-center flex-wrap">
            <img
              className="h-auto"
              src={img16}
              alt="Visa"
              style={{ width: "128px;" }}
            />
            <img
              className="h-auto"
              src={img17}
              alt="Mastercard"
              style={{ width: "128px;" }}
            />
            <img
              className="h-auto"
              src={img18}
              alt="Diners Club"
              style={{ width: "128px;" }}
            />
            <img
              className="h-auto"
              src={img19}
              alt="UnionPay"
              style={{ width: "128px;" }}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 col-lg-8">
            <h1 className="titleline text-start">
              Let’s talk about your requirements
            </h1>
            <InquiryForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSolution;
