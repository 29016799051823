import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BackgroundTrading from "../assets/images/Background/trading.png";
import img1 from "../assets/images/trading/img1.png";
import img2 from "../assets/images/trading/img2.png";
import img3 from "../assets/images/trading/img3.png";
import img4 from "../assets/images/trading/img4.png";
import img5 from "../assets/images/trading/img5.png";
import img6 from "../assets/images/trading/img6.png";
import img7 from "../assets/images/trading/img7.png";
import img8 from "../assets/images/trading/img8.png";
import img9 from "../assets/images/trading/img9.png";
import img10 from "../assets/images/trading/img10.png";
import img11 from "../assets/images/trading/img11.png";
import img12 from "../assets/images/trading/img12.png";
import img13 from "../assets/images/trading/img13.png";
import img14 from "../assets/images/trading/img14.png";
import { Helmet } from "react-helmet";
import { timeZones } from "./Timezones";
import InquiryForm from "./InquiryForm";
import axios from "axios";
const TradingPlatform = () => {
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedContactTime, setSelectedContactTime] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    timeZone: "",
    contactTime: "",
    inquiries: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://your-laravel-api-endpoint.com/api/submit",
        formData
      );
      console.log(response.data); // Handle the response as needed
    } catch (error) {
      console.error(error);
    }
  };
  const handleContactTimeChange = (event) => {
    setSelectedContactTime(event.target.value);
  };

  const handleTimeZoneChange = (event) => {
    setSelectedTimeZone(event.target.value);
  };
  const contactTimes = [
    { label: "6AM – 9PM", value: "6AM – 9PM" },
    { label: "9PM – 12PM", value: "9PM – 12PM" },
    { label: "12PM – 3PM", value: "12PM – 3PM" },
    { label: "3PM – 6PM", value: "3PM – 6PM" },
    { label: "6PM – 9PM", value: "6PM – 9PM" },
    { label: "9PM – 12PM", value: "9PM – 12PM" },
  ];

  return (
    <div>
      <Helmet>
        <title>Advanced Trading Platform | Fathena.com</title>
        <meta
          name="description"
          content="Experience superior trading with Athena's advanced platform. Offering real-time processing, high-speed trading, and reliable data analytics for Forex, stocks, gold, and cryptos. Optimize your trading strategy with our cutting-edge technology."
        />
        <meta
          name="keywords"
          content="Forex trading platform, gold trading, cryptocurrency trading, high-speed trading"
        />
      </Helmet>
      <div className="left-content">
        <div
          className="blue-bannar trading-platform-bg"
          style={{
            backgroundImage: `url(${BackgroundTrading})`,
            // backgroundPositionX: "-180px",
          }}
        >
          <div className="container-fluid">
            <div className="row  align-items-center flex-row-reverse">
              <div className="col-12 col-lg-8">
                <div className="blue-banner-c-w">
                  <h1>
                    <b>Empower Your Trade,</b>
                    <br />
                    Expand Your Horizons
                  </h1>
                  <ul className="bannar-list mt-2 mt-lg-0 p-0">
                    <li>
                      Fast and Efficient Trading | Fully Customizable Platforms
                      | MetaTrader 4 &amp; MetaTrader 5
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="MetaTraders-content mt-5 pt-lg-5 pt-1">
          <div className="row">
            <div className="col-12 col-lg-10">
              <h1
                className="section-title text-start"
                style={{ color: "rgb(68, 68, 68)" }}
              >
                MetaTrader 4 &amp; MetaTrader 5 <br />
                Your Gateway to the Markets
              </h1>
              <h3 className="bluetitle my-4">
                {" "}
                Chosen by Millions of Traders Worldwide.
              </h3>
              <p className="section-text2">
                Unlock the potential of the markets with MetaTrader. At Athena,
                we provide a straightforward, swift, and cost-effective
                brokerage startup package tailored for both emerging and growing
                brokerages eager to establish their own brand. Whether you’re
                just starting out or looking to expand, our MetaTrader solutions
                offer the perfect platform to connect with the global trading
                community.
              </p>
            </div>
            <div className="col-12">
              <div className="d-block d-md-none mt-4">
                <p className="section-text2 text-black">
                  Built-in social trading functions
                </p>
                <p className="py-4 section-text2 text-black">
                  Multi-language options in over 20 languages
                </p>
                <p className="section-text2 text-black">
                  Over 50 market analysis indicators
                </p>
              </div>
              <img className="w-100 h-auto" src={img1} alt="" />
              <div className="d-block d-md-none">
                <p className="section-text2 text-black">
                  Mobile, web and desktop versions
                </p>
                <p className="py-4 section-text2 text-black">
                  Multi-currency account including cryptocurrencies
                </p>
                <p className="section-text2 text-black">
                  Create your own trading robot
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="card-trading my-5 py-0 py-lg-4  ">
              <img src={img2} alt="500+ trading instruments" />
              <h3
                classNameName="section-title text-start"
                style={{ fontSize: "30px", padding: "0px" }}
              >
                500+ trading instruments
              </h3>
              <div className="check-p  py-4">
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Forex</p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Stocks &amp; Indices</p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Cryptocurrencies</p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Gold &amp; Silver</p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Oil and gas</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card-trading my-5 py-0 py-lg-4  ms-0 ms-lg-auto">
              <img src={img3} alt="Trading support tools" />
              <h3
                className="section-title text-start"
                style={{ fontSize: "30px", padding: "0px" }}
              >
                Trading support tools
              </h3>
              <div className="check-p  py-4">
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    News feeds on all markets including stocks and commodities
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Adjustable leverage system</p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Automated account opening bonus
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card-trading my-5 py-0 py-lg-4  ">
              <img src={img4} alt="Customizable Trader’s Room" />
              <h3
                className="section-title text-start"
                style={{ fontSize: "28px", padding: "0px" }}
              >
                Customizable Trader’s Room
              </h3>
              <div className="check-p  py-4">
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Built-in deposit and withdraw modules
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Advanced affiliate marketing tools
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Adjustable leverage system</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card-trading my-5 py-0 py-lg-4  ms-0 ms-lg-auto">
              <img src={img5} alt="Risk management tools" />
              <h3
                className="section-title text-start"
                style={{ fontSize: "30px", padding: "0px" }}
              >
                Risk management tools
              </h3>
              <div className="check-p  py-4">
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Aggregate liquidity with markup functions
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Auto switch to A book based on trading volume
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Customized trading activity reports
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Sophisticated dealer’s station
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card-trading my-5 py-0 py-lg-4  ">
              <img src={img6} alt="Multi-Level rebate structure" />
              <h3
                className="section-title text-start"
                style={{ fontSize: "30px", padding: "0px" }}
              >
                Multi-Level rebate structure
              </h3>
              <div className="check-p  py-4">
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Create different rebate for master IB and sub-IBs
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Rebates and commission rates base on points and <br />
                    trading volumes
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card-trading my-5 py-0 py-lg-4  ms-0 ms-lg-auto">
              <img src={img7} alt="24/7 Technical support" />
              <h3
                className="section-title text-start"
                style={{ fontSize: "30px", padding: "0px" }}
              >
                24/7 Technical support
              </h3>
              <div className="check-p  py-4">
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Trading hours and symbol setting
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">IP monitoring and blocking</p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Holiday trading hour notification
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">Swap rate adjustment</p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Automated daily trading report
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Automatic backup in real time
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center py-1">
                  <div className="check-t">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(85, 132, 255)" }}
                    >
                      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                    </svg>
                  </div>
                  <p className="text-black mb-0">
                    Proxy servers around the world
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="MobileSection py-5 px-2 px-lg-5 my-2 my-lg-5"
        style={{ background: "rgb(14, 27, 54)" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4">
              <img className="w-100 h-auto p-5 p-lg-2" src={img8} alt="" />
            </div>
            <div className="col-12 col-md-8">
              <h3 className="section-title text-white text-lg-start">
                Download MetaTrader
              </h3>
              <div className=" d-flex flex-wrap gap-4 justify-content-center justify-content-lg-start mt-0 mt-lg-5 mobile-btn-down">
                <div className="d-none d-lg-block">
                  <a
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                    tabindex="0"
                    type="button"
                    href="https://download.mql5.com/cdn/web/athena.technology.limited/mt4/athenatechnology4setup.exe"
                  >
                    <span className="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M480 265H232v179l248 36V265zm-264 0H32v150l184 26.7V265zM480 32 232 67.4V249h248V32zM216 69.7 32 96v153h184V69.7z"></path>
                      </svg>
                    </span>
                    Windows
                    <span className="MuiTouchRipple-root css-w0pj6f"></span>
                  </a>
                </div>
                <a
                  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                  tabindex="0"
                  type="button"
                  href="https://apps.apple.com/us/app/metatrader-4/id496212596"
                >
                  <span className="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M349.13 136.86c-40.32 0-57.36 19.24-85.44 19.24-28.79 0-50.75-19.1-85.69-19.1-34.2 0-70.67 20.88-93.83 56.45-32.52 50.16-27 144.63 25.67 225.11 18.84 28.81 44 61.12 77 61.47h.6c28.68 0 37.2-18.78 76.67-19h.6c38.88 0 46.68 18.89 75.24 18.89h.6c33-.35 59.51-36.15 78.35-64.85 13.56-20.64 18.6-31 29-54.35-76.19-28.92-88.43-136.93-13.08-178.34-23-28.8-55.32-45.48-85.79-45.48z"></path>
                      <path d="M340.25 32c-24 1.63-52 16.91-68.4 36.86-14.88 18.08-27.12 44.9-22.32 70.91h1.92c25.56 0 51.72-15.39 67-35.11 14.72-18.77 25.88-45.37 21.8-72.66z"></path>
                    </svg>
                  </span>
                  Apple<span className="MuiTouchRipple-root css-w0pj6f"></span>
                </a>
                <a
                  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary fathena-btn mt-3 mt-lg-4 css-1hw9j7s"
                  tabindex="0"
                  type="button"
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&amp;hl=en&amp;referrer=ref_id%3d5188185631892253929%26server%3dPSS-Demo%252cPSS-Live"
                >
                  <span className="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m380.91 199 42.47-73.57a8.63 8.63 0 0 0-3.12-11.76 8.52 8.52 0 0 0-11.71 3.12l-43 74.52c-32.83-15-69.78-23.35-109.52-23.35s-76.69 8.36-109.52 23.35l-43-74.52a8.6 8.6 0 1 0-14.88 8.64L131 199C57.8 238.64 8.19 312.77 0 399.55h512c-8.19-86.78-57.8-160.91-131.09-200.55zM138.45 327.65a21.46 21.46 0 1 1 21.46-21.46 21.47 21.47 0 0 1-21.46 21.46zm235 0A21.46 21.46 0 1 1 395 306.19a21.47 21.47 0 0 1-21.51 21.46z"></path>
                    </svg>
                  </span>
                  Android
                  <span className="MuiTouchRipple-root css-w0pj6f"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5 py-2 py-lg-5">
          <div className="col-12 col-md-10 col-lg-6">
            <h1 className="section-title text-start text-larger">
              Investment Management Tools
            </h1>
            <h3 className="section-title text-start my-4">Trading Robots</h3>
            <p className="section-text2">
              Unlock the potential of the markets with MetaTrader. At Athena, we
              provide a straightforward, swift, and cost-effective brokerage
              startup package tailored for both emerging and growing brokerages
              eager to establish their own brand. Whether you’re just starting
              out or looking to expand, our MetaTrader solutions offer the
              perfect platform to connect with the global trading community.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-none d-lg-block"></div>
          <div className="col-12 col-md-10 col-lg-6">
            <h3 className="section-title text-start">Signal Trading</h3>
            <p className="section-text2">
              Connect to thousands of traders and copy their trades instantly
              using your trading platform.
            </p>
          </div>
          <div className="col-12 px-1 px-lg-5">
            <img className="w-100 h-auto" src={img9} alt="" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5 py-2 py-lg-5">
          <div className="col-12 col-md-10 col-lg-6">
            <h1 className="section-title text-start p-0 text-larger">
              Technical Analysis Tools
            </h1>
            <h3 className="bluetitle text-start">
              The full collection of analytical tools to predict market
              movements.
            </h3>
            <div className="check-p py-4">
              <p className="align-items-center text-black d-flex gap-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  className="mb-1"
                  height="18"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(85, 132, 255)" }}
                >
                  <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                </svg>
                Use 80 + technical indicators and analytical objects
              </p>
              <p className="align-items-center text-black d-flex gap-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  className="mb-1"
                  height="18"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(85, 132, 255)" }}
                >
                  <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                </svg>
                Open 100 charts to monitor all markets
              </p>
              <p className="align-items-center text-black d-flex gap-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  className="mb-1"
                  height="18"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(85, 132, 255)" }}
                >
                  <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29-134.4 160a16 16 0 0 1-12 5.71h-.27a16 16 0 0 1-11.89-5.3l-57.6-64a16 16 0 1 1 23.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0 1 24.5 20.58z"></path>
                </svg>
                Examine price movement in 21 timeframes
              </p>
            </div>
          </div>
          <div className="col-12">
            <img className="w-100 h-auto px-1 px-lg-5" src={img10} alt="" />
          </div>
        </div>
      </div>
      <div className="container py-2 py-lg-5 my-5">
        <div className="row ">
          <div className="col-12 col-md-10 col-lg-6">
            <h1 className="section-title text-start text-larger">
              MetaTrader API
            </h1>
            <h3 className="bluetitle text-start">
              Athena offers public interfaces (Application Programming
              Interface, API) to add, integrate and customize.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 my-4">
            <div
              className="card border-0 rounded-0 p-4 p-lg-5 w-100 h-100"
              style={{ background: "rgb(243, 245, 248)" }}
            >
              <div className="img-h">
                <img className="card-img" src={img11} alt="Manager API" />
              </div>
              <h3 className="section-title text-start my-4 p-0">Manager API</h3>
              <h4>
                For development of additional functions and platform integration
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 my-4">
            <div
              className="card border-0 rounded-0 p-4 p-lg-5 w-100 h-100"
              style={{ background: "rgb(243, 245, 248)" }}
            >
              <div className="img-h">
                <img className="card-img" src={img12} alt="DataFeed API" />
              </div>
              <h3 className="section-title text-start my-4 p-0">
                DataFeed API{" "}
              </h3>
              <h4>For developing and publishing news and quote data feeds </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 my-4">
            <div
              className="card border-0 rounded-0 p-4 p-lg-5 w-100 h-100"
              style={{ background: "rgb(243, 245, 248)" }}
            >
              <div className="img-h">
                <img className="card-img" src={img13} alt="Report API" />
              </div>
              <h3 className="section-title text-start my-4 p-0">Report API </h3>
              <h4>
                For creating customized reports on operations and traders{" "}
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 my-4">
            <div
              className="card border-0 rounded-0 p-4 p-lg-5 w-100 h-100"
              style={{ background: "rgb(243, 245, 248)" }}
            >
              <div className="img-h">
                <img className="card-img" src={img14} alt="WebServices API" />
              </div>
              <h3 className="section-title text-start my-4 p-0">
                WebServices API{" "}
              </h3>
              <h4>For integration of the platform with websites </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 col-lg-8">
            <h1 className="titleline text-start">
              Let’s talk about your requirements
            </h1>
            <InquiryForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingPlatform;
