import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Background from "../assets/images/Background/HostingService-Main-min.jpg";
import map1 from "../assets/images/map1.png";
import map2 from "../assets/images/map2.png";
import map3 from "../assets/images/map3.png";
import { Helmet } from "react-helmet";
const HostingService = () => {
  return (
    <div>
      <Helmet>
        <title>AWS Cloud Hosting Services | Fathena.com</title>
        <meta
          name="description"
          content="Leverage AWS Cloud Data Centers for financial hosting services at Athena. Enjoy 99.9999% 
        uptime, low-latency trading below 10ms, and robust security with compliance to SOC 1 & 2, ISO 50001, ISO 27001, and PCI-DSS."
        />
        <meta
          name="keywords"
          content="low latency trading, secure trading server hosting"
        />
      </Helmet>
      <div className="hosting-page">
        <div className="hosting-banner">
          <div
            className="blue-bannar hosting-banner"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="container-fluid">
              <div className="row my-4 align-items-center ">
                <div className="col-12 col-lg-8">
                  <div className="blue-banner-c-w">
                    <h1>
                      <b>Athena Hosting</b>
                      <br />
                      Secure, Reliable, Always On
                    </h1>
                    <ul className="bannar-list mt-2 mt-lg-0 p-0">
                      <li>
                        100% Uptime SLA | 99.9999% Data <br /> Center Uptime |
                        Free DDoS Protection <br /> Optimized for Brokers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="BrandSPaceHomePage">
          <div className="">
            <div className="container">
              <div className="row my-5">
                <div className="col-12 col-md-10 col-lg-6">
                  <h1
                    className="section-title text-larger pb-1 pb-lg-4"
                    style={{ color: "rgb(14, 27, 54)", textAlign: "left" }}
                  >
                    Our Data Centers
                  </h1>
                  <h3 className="section-title text-start">
                    Equinix’s London International Business Exchange™ (IBX®)
                  </h3>
                  <h3 className="bluetitle">
                    Europe’s largest multi-asset trading community
                  </h3>
                </div>
              </div>
              <div className="row my-5">
                <div className="col-1 d-none d-md-block"></div>
                <div className="col-12 col-md-4">
                  <div className="box-blue h-100">
                    <ul className="p-0">
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        OHSAD 18001
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        ISO 9001
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        SSAE 16/ISAE 3402
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        ISO 27001
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        PCI DSS
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        ISO 50001
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-0">
                  <div className="box-blue h-100">
                    <ul className="p-0">
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        99.9999% Uptime Record
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        Less than 10ms of Local Latency
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        200+ Network Service Providers
                      </li>
                      <li className="d-flex gap-2 text-white pb-2">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="mt-1"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                        </svg>
                        70,250+ Square Meters of Colocation Space
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-1 d-none d-md-block"></div>
              </div>
              <p className="section-text2 HostingpageP">
                Connection to Europe’s extensive financial service market
                through the Slough campus (LD4, LD5, LD6, LD7, LD10, LD13x data
                centers) Highly secure and hosts Europe’s largest multilateral
                trading facility, BATS Chi-X, and FX trading system ICAP’s EBS.
              </p>
              <img
                className="HostingMapimages w-100 h-auto px-0 px-lg-5 mt-4 mt-lg-5"
                src={map1}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="hosting-bg-1">
          <div className="container ">
            <div className="Equinix-content mt-5">
              <div className="col-12 col-md-10 col-lg-7 mt-5">
                <h3 className="section-title" style={{ textAlign: "left" }}>
                  Equinix FR2 International Business Exchange™ (IBX®)
                </h3>
                <h3 className="bluetitle">
                  Direct interconnection infrastructure for major banks in
                  Germany
                </h3>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-12 col-md-4">
                <div className="box-blue h-100">
                  <ul className="p-0">
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      SOC 1 Type II
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      PCI-DSS
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      SOC 2 Type II
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      ISO 27001
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      ISO 9001:2015
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      ISO 14001:2015
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      ISO 22301
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      ISO 50001
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      OHSAS 18001
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <div className="box-blue h-100">
                  <ul className="p-0">
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      N+1 Power Redundancy
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      N+1 Cooling Redundancy
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      99.9999% Uptime Record
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      Less than 10ms of Local Latency
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-1 d-none d-md-block"></div>
            </div>
            <p className="section-text2 HostingpageP">
              European gateway to global trading. <br />
              Equinix Frankfurt’s financial ecosystem. <br />
              Hosts major players in global electronic payment. <br />
              Over 150+ financial services firms and 20+ trading venues. <br />
              Supports the storage, connectivity and compliance needs of payment
              services providers.
            </p>
            <img
              className="HostingMapimages w-100 h-auto px-0 px-lg-5 my-4 my-lg-5"
              src={map2}
              alt=""
            />
          </div>
        </div>
        <div className="hosting-bg-2">
          <div className="BrandSPaceHomePage">
            <div className="">
              <div className="container ">
                <div className="Equinix-content">
                  <div className="col-12 col-lg-7 col-md-10">
                    <h3 className="section-title" style={{ textAlign: "left" }}>
                      Equinix NY2 International Business Exchange™ (IBX®)
                    </h3>
                    <h3 className="bluetitle">
                      Data center for the world’s largest financial institutions
                    </h3>
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-1 d-none d-md-block"></div>
                  <div className="col-12 col-md-4">
                    <div className="box-blue h-100">
                      <ul className="p-0">
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          SOC 1 Type II
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          PCI-DSS
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          SOC 2 Type II
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          ISO 27001
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          HIPAA
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          NIST 800-53/FISMA
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-md-0">
                    <div className="box-blue h-100">
                      <ul className="p-0">
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          N+1 Power Redundancy
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          N+1 Cooling Redundancy
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          99.9999% Uptime Record
                        </li>
                        <li className="d-flex gap-2 text-white pb-2">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mt-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                          </svg>
                          Less than 10ms of Local Latency
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-1 d-none d-md-block"></div>
                </div>
                <p className="section-text2 HostingpageP">
                  Equinix Financial Exchange with low latency connectivity to a
                  large ecosystem of financial firms including BATS, CBOE, ICAP,
                  Knight Capital, ISE and BOX.{" "}
                </p>
                <img
                  className="HostingMapimages w-100 h-auto px-2 px-lg-5 my-4 my-lg-5"
                  src={map3}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="Equinix-content">
              <div className="col-12 col-md-10 col-lg-7">
                <h3 className="section-title" style={{ textAlign: "left" }}>
                  Equinix Singapore Data Centers
                </h3>
                <h3 className="bluetitle">
                  Servicing most banks and FX liquidity providers in Southeast
                  Asia
                </h3>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-1 d-none d-md-block"></div>
              <div className="col-12 col-md-4">
                <div className="box-blue h-100">
                  <ul className="p-0">
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      SOC 1 Type II
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      SOC 2 Type II
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      SS 564 Energy Efficiency
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      ISO 27001
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      PCI-DSS
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <div className="box-blue h-100">
                  <ul className="p-0">
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      99.9999% Uptime Record
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      Less than 10ms of Local Latency
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      205+ Network Service Providers
                    </li>
                    <li className="d-flex gap-2 text-white pb-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="mt-1"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                      </svg>
                      41,400+ Square Meters of Colocation Space
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-1 d-none d-md-block"></div>
            </div>
            <p className="section-text2 HostingpageP">
              Financial community and ecosystem located in Southeast Asia with
              the access to 205+ network providers.
            </p>
          </div>
        </div>
        <div className="BrandSPaceHomePage">
          <div className="container">
            <div className="Equinix-content">
              <div className="col-12 col-lg-6 col-md-10 mb-5">
                <h3 className="section-title" style={{ textAlign: "left" }}>
                  AWS Cloud Data Centers
                </h3>
                <h3 className="bluetitle">
                  Comprehensive Compliance / Exceptional Uptime / Low Latency
                  Connectivity / Extensive Network / Scalable Infrastructure /
                  Global Reach
                </h3>
              </div>
            </div>
            <p className="section-text2 HostingpageP">
              AWS Cloud Data Centers deliver global strategic advantages,
              adhering to stringent compliance standards including SOC 1 Type
              II, SOC 2 Type II, ISO 50001, ISO 27001, and PCI-DSS for robust
              security and data protection. They offer a 99.9999% uptime record,
              essential for minimizing disruptions in financial services. With
              low latency connectivity of less than 10ms globally, AWS supports
              high-speed trading and real-time data processing. The extensive
              network of AWS Direct Connect partners ensures secure
              connectivity, while a growing number of global data centers
              enhances scalability and operational expansion. AWS serves the
              financial sector worldwide, providing access to a broad network of
              services and partners.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostingService;
