import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import contactus from "../assets/images/contactus.png";
import { Helmet } from "react-helmet";
import ContactForm from "./ContactForm";
const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Athena | Get In Touch with Our Financial Experts</title>
        <meta
          name="description"
          content="Reach out to Athena for any inquiries or support needs. Our team is ready to assist you with your financial services needs, providing expert advice and solutions."
        />
        <meta
          name="keywords"
          content="contact Athena, Athena customer service"
        />
      </Helmet>
      <div className="container" style={{ paddingTop: "100px" }}>
        <div className="row my-5 ">
          <div className="col-12 col-md-6 pe-lg-5">
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <h3
                  className="bluetitle text-start"
                  style={{ color: "rgb(68, 68, 68)" }}
                >
                  Contact Us
                </h3>
                <p
                  className="section-text2 "
                  style={{ color: "rgb(68, 68, 68)" }}
                >
                  Whether you have inquiries, feedback, or need specific
                  information, our team is here to help.
                </p>
                <ul className="p-0 mt-4" style={{ listStyle: "none" }}>
                  <li>
                    <h6 className="fw-bold"> Athena Technology Limited (UK)</h6>
                  </li>
                  <li className="">
                    <p
                      className="mt-2 mb-0"
                      style={{ color: "rgb(68, 68, 68)" }}
                    >
                      {" "}
                      Address: 71-75 Shelton Street, Covent Garden, London, WC2H
                      9JQ, United Kingdom
                    </p>
                  </li>
                  <li className="">
                    <p
                      className="mt-2 mb-0"
                      style={{ color: "rgb(68, 68, 68)" }}
                    >
                      {" "}
                      Phone:
                      <a href="tel:  +44 20 8089 9460"> +44 20 8089 9460</a>
                    </p>
                  </li>
                  <li className="">
                    <p
                      className="mt-2 mb-0"
                      style={{ color: "rgb(68, 68, 68)" }}
                    >
                      {" "}
                      Email:
                      <a href="mailto:  admin@fathena.com">
                        {" "}
                        admin@fathena.com
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <img className="w-100 h-auto" src={contactus} alt="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 ps-lg-5">
            <ContactForm />
            <p className="section-text2 pt-4">
              Your privacy is important to us, and we will handle your
              information in accordance with our
              <a className="ps-2" href="/privacy-policy">
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
