// routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route from react-router-dom
import Home from './components/Home';
import HostingService from './components/HostingService';
import TradingPlatform from './components/TradingPlatform';
import PaymentSolution from './components/PaymentSolution';
import AggregatedLiquidity from './components/AggregatedLiquidity';
import BrandIdentity from './components/BrandIdentity';
import WebDesign from './components/WebDesign';
import WebDevelopment from './components/WebDevelopment';
import DigitalMarketing from './components/DigitalMarketing';
import WebMaintenance from './components/WebMaintenance';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/hosting-service" element={<HostingService />} />
    <Route path="/trading-platform" element={<TradingPlatform />} />
    <Route path="/payment-solution" element={<PaymentSolution />} />
    <Route path="/aggregated-liquidity" element={<AggregatedLiquidity />} />
    <Route path="/brand-identity" element={<BrandIdentity />} />
    <Route path="/web-design" element={<WebDesign />} />
    <Route path="/web-development" element={<WebDevelopment />} />
    <Route path="/digital-marketing" element={<DigitalMarketing />} />
    <Route path="/web-maintenance" element={<WebMaintenance />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/terms-of-use" element={<Terms />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

  </Routes>
);

export default AppRoutes;
